import {firestore} from "../firebase";

const db = firestore.collection("/surveikepuasan");

const getSurvei = (gdate,ldate) => {
  return db.orderBy("tanggal").where("tanggal",">",gdate).where("tanggal","<",ldate);
};

const getSurveiTp = (gdate,ldate) => {
  return db.orderBy("tanggal").where("tanggal",">",gdate).where("tanggal","<",ldate).where("rating","==","1");
};

const getSurveiP = (gdate,ldate) => {
  return db.orderBy("tanggal").where("tanggal",">",gdate).where("tanggal","<",ldate).where("rating","==","2");
};

const getSurveiSp = (gdate,ldate) => {
  return db.orderBy("tanggal").where("tanggal",">",gdate).where("tanggal","<",ldate).where("rating","==","3");
};

const create = (data) => {
  return db.add(data);
};

const update = (id, value) => {
  return db.doc(id).update(value);
};

const remove = (id) => {
  return db.doc(id).delete();
};

const SurveiService = {
  getSurvei,
  getSurveiTp,
  getSurveiP,
  getSurveiSp,
  create,
  update,
  remove
};

export default SurveiService;