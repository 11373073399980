import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import AdminDataService from "../../Services/AdminService";
import AdminDetail from "./AdminDetail"
import Navigation from "../Navigation"

function AdminList() {

  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [admins, loading, error] = useCollection(AdminDataService.getAll().orderBy("createdAt", "asc"));

  const refreshList = () => {
    setCurrentAdmin(null);
    setCurrentIndex(-1);
  };

  const setActiveAdmin = (admin, index) => {
    const { createdAt, displayName, email, photoURL } = admin.data();

    setCurrentAdmin({
      id: admin.id,
      createdAt,
      displayName,
      email,
      photoURL,
    });

    setCurrentIndex(index);
  };

  return(
      <div>
        <Navigation />  
        <div className="list row mt-20 mx-10">
        <div className="col-md-6">
        <h1 className="text-3xl mb-2 text-center font-bold">Daftar Admin</h1>
          {error && <strong>Error: {error}</strong>}
          {loading && <span>Memuat data...</span>}
          <ul className="list-group">
            { !loading &&
              admins &&
              admins.docs.map((admin, index) => (
                <li
                  className={"list-group-item " + (index === currentIndex ? "active" : "")}
                  onClick={() => setActiveAdmin(admin, index)}
                  key={admin.id}
                >
                  {index+1}.{' '}{ admin.data().displayName }
                </li>
              ))}
          </ul>
        </div>
        <div className="col-md-6">
          {currentAdmin ? (
            <AdminDetail admin={currentAdmin} refreshList={refreshList} />
          ) : (
            <div>
              <br/><br/><br/>
              <p><strong align='center'>Pilih salah satu data untuk melihat detailnya...</strong></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminList;