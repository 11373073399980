import React, { useState } from "react";
import KeluargaDataService from "../../Services/KeluargaService";
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'


const KeluargaDetail = (props) => {
  var { iduser } = useParams()

  const initialKeluargaState = {
    key: null,
    nama: "",
    nik: "",
    hubungan: "",
  };
  const [currentKeluarga, setCurrentKeluarga] = useState(initialKeluargaState);

  const { keluarga } = props;
  if (currentKeluarga.id !== keluarga.id) {
    setCurrentKeluarga(keluarga);
  }

  function showMessage (m) {
    Swal.fire({
      text: m,
      icon: 'info',
    }).then(()=>{
      props.refreshList()
    })
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentKeluarga({ ...currentKeluarga, [name]: value });
  };

  const updateKeluarga = () => {
    Swal.fire({
      title: 'Ubah data?',
      text: "data akan diperbarui sesuai informasi saat ini",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          hubungan: currentKeluarga.hubungan,
          nama: currentKeluarga.nama,
          nik: currentKeluarga.nik,
        };
        KeluargaDataService.update(iduser, currentKeluarga.id, data)
          .then(() => {
            showMessage("Data keluarga berhasil diubah");
          })
          .catch((e) => {
            console.log(e);
          });
      }
    })
  };

  const deleteKeluarga = () => {
    Swal.fire({
      title: 'Yakin ingin menghapus?',
      text: "data yang terhapus tidak dapat dikembalikan!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus'
    }).then((result) => {
      if (result.isConfirmed) {
        KeluargaDataService.remove(iduser, currentKeluarga.id)
        .then(() => {
          props.refreshList();
        })
        .catch((e) => {
          console.log(e)
          showMessage(e)
      });
      }
    })
  };

  return (
    <div className='mx-10'>
        {currentKeluarga ? (
        <div className="edit-form">
          <h1 className="text-3xl mb-2 text-center font-bold">Detail Data Keluarga Lainnya (Sebagai Pengikut)</h1>
          <form>

          <div className="form-group">
              <label htmlFor="nama">Nama</label>
              <input
                type="text"
                className="form-control"
                id="nama"
                name="nama"
                value={currentKeluarga.nama}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="nik">NIK</label>
              <input
                type="text"
                className="form-control"
                id="nik"
                name="nik"
                value={currentKeluarga.nik}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="hubungan">Hubungan dengan Pengguna</label>
              <input
                type="text"
                className="form-control"
                id="hubungan"
                name="hubungan"
                value={currentKeluarga.hubungan}
                onChange={handleInputChange}
              />
            </div>

        </form>

          <button className="btn btn-danger mr-2" onClick={deleteKeluarga}>
            Hapus
          </button>

          <button className="btn btn-success mr-2" onClick={updateKeluarga}>
            Simpan Perubahan
          </button>
          
        </div>
        ) : (
        <div>
          <br/>
          <p><strong>Pilih salah satu data untuk melihat detailnya...</strong></p>
        </div>
      )}
    </div>
  );
};

export default KeluargaDetail;