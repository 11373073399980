import React, { useState, useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import KunjunganDataService from "../../Services/KunjunganService";
import KunjunganDetail from "./KunjunganDetail";
import DatePicker from 'react-date-picker';
import Navigation from "../Navigation";

function PenggunaList() {
  const [dateValue, setDateValue] = useState(new Date());

  const getLessDate = (setgdate) => {
    var lessDate = new Date(`${setgdate.getMonth()+1}/${setgdate.getDate()}/${setgdate.getFullYear()} 23:59:01`);
    return lessDate;
  }

  const getGreaterDate = (setldate) => {
    var greaterDate = new Date(`${setldate.getMonth()+1}/${setldate.getDate()}/${setldate.getFullYear()} 00:00:01`);
    return greaterDate;
  }

  const [gdateValue, setGdateValue] = useState(getGreaterDate(dateValue));
  const [ldateValue, setLdateValue] = useState(getLessDate(dateValue));
  const queryRef = KunjunganDataService.getRegistered(gdateValue,ldateValue);
  const [limit, setLimit] = useState(20);
  const [doclength, setDoclength] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [currentKunjungan, setCurrentKunjungan] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [kunjungans, loading, error] = useCollection(queryRef.limit(limit));
  
  const onDateChange = (date) => {
    setDateValue(date);
    setGdateValue(getGreaterDate(date));
    setLdateValue(getLessDate(date));
  }
  
  const getCountDoc = () => {
    queryRef.get().then(snapshot => {
      setDoclength(snapshot.size);
      }).catch(err => {
      console.log("Error getting documents", err);
    });
  };
  getCountDoc();
  
  const refreshList = () => {
    setCurrentKunjungan(null);
    setCurrentIndex(-1);
  };

  const setActiveKunjungan = (kunjungan, index) => {
    const { alamatpengunjung, daftarjam, fotopengunjung, fotowbp, hubungan, kamar, kasus, namabinwbp,
      namapengunjung, namawbp, noidwbp, noktp, pengikut1, pengikut2, pengikut3, sudahkunjungan, } = kunjungan.data();

    setCurrentKunjungan({
      id: kunjungan.id,
      alamatpengunjung, daftarjam, fotopengunjung, fotowbp, hubungan, kamar, kasus, namabinwbp,
      namapengunjung, namawbp, noidwbp, noktp, pengikut1, pengikut2, pengikut3, sudahkunjungan,
    });

    setCurrentIndex(index);
  };

  useEffect(() => {
    if(doclength>0){
      if(doclength<=limit){
        setHasMore(false);
      }else{
        setHasMore(true);
      }
    }else{return false;}
  });

  return(
      <div>
        <Navigation />  
        <div className="list row mt-20 mx-10">
        <div className="col-md-6">
        <h1 className="text-3xl mb-2 text-center font-bold">Daftar Riwayat Kunjungan WBP</h1>
        <div className="form-group">
          <label htmlFor="tanggal">Pilih Tanggal Pendaftaran</label><br/>
          <DatePicker
            id="tanggal"
            clearIcon={null}
            onChange={onDateChange}
            value={dateValue}
          /></div><hr/><br/>
          {error && <strong>Error: {error}</strong>}
          {loading && <span>Memuat data...</span>}
          <ul className="list-group">
            { !loading && kunjungans &&
              kunjungans.docs.map((kunjungan, index) => (
                <li
                  className={"list-group-item " + (index === currentIndex ? "active" : "")}
                  onClick={() => setActiveKunjungan(kunjungan, index)}
                  key={kunjungan.id}
                >
                  {index+1}.{' '}{ kunjungan.data().namapengunjung }
                </li>
              ))}
              {hasMore && <button className="btn btn-secondary" onClick={() => setLimit(limit+20)}>Lihat lainnya</button>}
          </ul>
        </div>
        <div className="col-md-6">
          {currentKunjungan ? (
            <KunjunganDetail kunjungan={currentKunjungan} refreshList={refreshList} />
          ) : (
            <div>
              <br/><br/><br/>
              <p><strong align='center'>Pilih salah satu data untuk melihat detailnya...</strong></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PenggunaList;