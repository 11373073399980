import {firestore} from "../firebase";

const db = firestore.collection("/petugas");

const getAll = () => {
  return db.orderBy("nama","asc");
};

const create = (id,data) => {
  return db.doc(id).set(data);
};

const update = (id, value) => {
  return db.doc(id).update(value);
};

const remove = (id) => {
  return db.doc(id).delete();
};

const PetugasService = {
  getAll,
  create,
  update,
  remove
};

export default PetugasService;