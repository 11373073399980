import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import SignIn from "./SignIn";
import PasswordReset from "./PasswordReset";
import AddAdmin from "./MAdmin/AddAdmin";
import Dashboard from "./Dashboard";
import ProfilePage from "./ProfilePage";
import { AuthProvider } from "../Services/Authentication";
import { PrivateRoute } from "../Services/PrivateRoute";
import AdminList from "./MAdmin/AdminList";
import PenggunaList from "./MPengguna/PenggunaList";
import PAPList from "./MPengguna/PermohonanPenggunaList";
import WBpList from "./MWbp/WbpList";
import ChatDetail from "./MChat/ChatDetail";
import PHWSList from "./MWbp/PermohonanWbpList";
import PKB from "./MKirang/ProsesKirang";
import PKBSelf from "./MKirang/ProsesKirangSelf";
import RKB from "./MKirang/KirangList";
import PETList from "./MPetugas/PetugasList";
import AddPET from "./MPetugas/AddPetugas";
import SKList from "./MSurvei/SurveiList";
import TFList from "./Mtraffic/TrafficList";
import CTList from "./MControl/ControlList";
import ProcKunj from "./MKunjungan/ProsesKunjungan";
import ProcKunjSelf from "./MKunjungan/ProsesKunjunganSelf";
import RKunj from "./MKunjungan/KunjunganList";
import PelList from "./MPelaporan/PelaporanList";
import ChatList from "./MChat/ChatList";
import NewsList from "./MBerita/BeritaList";
import AddNews from "./MBerita/AddBerita";
import PrivPol from "./PrivPol";
import PermohKel from "./MKeluarga/PermohonanKeluargaList";
import KelList from "./MKeluarga/KeluargaList";
import AddKel from "./MKeluarga/AddKeluarga";
import Self from "./SelfServices";
function Application() {
  return (
    <AuthProvider>
      <Router>
          <Route exact path="/login" component={SignIn} />
          <Route  exact path="/resetpassword" component={PasswordReset} />
          <Route  exact path="/privacy-policy" component={PrivPol} />
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/ss" component={Self} />
          <PrivateRoute exact path="/profile" component={ProfilePage} />
          <PrivateRoute  exact path="/addadmin" component={AddAdmin} />
          <PrivateRoute  exact path="/admins" component={AdminList} />
          <PrivateRoute  exact path="/users" component={PenggunaList} />
          <PrivateRoute  exact path="/paps" component={PAPList} />
          <PrivateRoute exact path="/wbpnya/:iduser" component={WBpList} />
          <PrivateRoute exact path="/chatnya/:iduser/:username" component={ChatDetail} />
          <PrivateRoute  exact path="/phws" component={PHWSList} />
          <PrivateRoute  exact path="/prokb" component={PKB} />
          <PrivateRoute exact path="/ssprokb" component={PKBSelf} />
          <PrivateRoute  exact path="/riwkb" component={RKB} />
          <PrivateRoute  exact path="/addnews" component={AddNews} />
          <PrivateRoute  exact path="/newss" component={NewsList} />
          <PrivateRoute  exact path="/addpetugas" component={AddPET} />
          <PrivateRoute  exact path="/petugass" component={PETList} />
          <PrivateRoute  exact path="/sk" component={SKList} />
          <PrivateRoute  exact path="/pelaporan" component={PelList} />
          <PrivateRoute  exact path="/chat" component={ChatList} />
          <PrivateRoute  exact path="/traffic" component={TFList} />
          <PrivateRoute  exact path="/control" component={CTList} />
          <PrivateRoute  exact path="/kunjungan" component={ProcKunj} />
          <PrivateRoute  exact path="/sskunjungan" component={ProcKunjSelf} />
          <PrivateRoute  exact path="/riwkunjungan" component={RKunj} />
          <PrivateRoute  exact path="/permohkellist" component={PermohKel} />
          <PrivateRoute  exact path="/keluarganya/:iduser" component={KelList} />
          <PrivateRoute  exact path="/addkeluarganya/:iduser/:username" component={AddKel} />
      </Router>
    </AuthProvider>
  );
}

export default Application;
