import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Application from "./Components/Application";
function App() {
  
  return (
      <Router>
        <Application/>
      </Router>
  );
}

export default App;
