import {firestore, storage} from "../firebase";

const db = firestore.collection("/user");

const getVerifiedWbp = (iduser, searchvalue) => {
  return db.doc(iduser).collection("wbp").orderBy("namawbp","asc").where("statuspenambahan","==","acc").startAt(searchvalue).endAt(searchvalue + "\uf8ff");
};

const getRequestingWbp = () => {
  const datanya = [];
  db.where("sudahaktif","==",true).get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        db.doc(doc.id).collection("wbp").where("statuspenambahan","==","proccess").orderBy("namawbp","asc").get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              datanya.push({...doc.data(), id: doc.id})
            })
          }).catch(err => {
            console.log("Error getting sub-collection documents", err);
          })
      });
    }).catch(err => {
    console.log("Error getting documents", err);
  });
  return datanya;
};

const create = (data) => {
  return db.add(data);
};

const update = (iduser, id, value) => {
  return db.doc(iduser).collection("wbp").doc(id).update(value);
};

const remove = (iduser, id) => {
  return db.doc(iduser).collection("wbp").doc(id).delete();
};

const delfoto = (url) => {
  var fileRef = storage.refFromURL(url);
  return fileRef.delete();
};

const WbpService = {
  getVerifiedWbp,
  getRequestingWbp,
  create,
  update,
  remove,
  delfoto
};

export default WbpService;