import React, { useState } from "react";
import { Link } from "react-router-dom";
import WbpDataService from "../../Services/WbpService";
import PermohonanWbpDetail from "./PermohonanWbpDetail"
import Navigation from "../Navigation"

function PermohonanWbpList() {
  const [wbps] = useState(WbpDataService.getRequestingWbp());
  const [currentWbp, setCurrentWbp] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);


  const refreshList = () => {
    setCurrentWbp(null);
    setCurrentIndex(-1);
  };

  const handleHideBT = (event) => {
    event.target.style.display = 'none';
 }

  const setActiveWbp = async (wbp, index) => {
    const { fotowbp, hubungan, kamar, kasus, ktpuser, namabinwbp, namauser, namawbp, noidwbp, statuspenambahan } = wbp;

    setCurrentWbp({
      id: wbp.id,
      fotowbp,
      hubungan,
      kamar,
      kasus,
      ktpuser,
      namabinwbp,
      namauser,
      namawbp,
      noidwbp,
      statuspenambahan,
    });

    setCurrentIndex(index);
  };

  return(
      <div>
        <Navigation />  
        <div className="list row mt-20 mx-10">
        <div className="col-md-6">
        <h1 className="text-3xl mb-2 text-center font-bold">Permohonan Terhubung WBP</h1>
          <ul className="list-group">
            { wbps.map(function(wbp, index){
                return <li
                className={"list-group-item " + (index === currentIndex ? "active" : "")}
                onClick={() => setActiveWbp(wbp, index)}
                key={wbp.id}
              >
                {index+1}.{' '}{ wbp.namawbp }{' Bin '}{wbp.namabinwbp}
              </li>
          })}
          </ul>
          <Link to="/phws#" onClick={handleHideBT} className="btn btn-primary">Tampilkan Daftar</Link>
        </div>
        <div className="col-md-6">
          {currentWbp ? (
            <PermohonanWbpDetail wbp={currentWbp} refreshList={refreshList} />
          ) : (
            <div>
              <br/><br/><br/>
              <p><strong align='center'>Pilih salah satu data untuk melihat detailnya...</strong></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PermohonanWbpList;