import {firestore} from "../firebase";

const db = firestore.collection("/parkir");

const getTraffic = (gdate,ldate) => {
  return db.orderBy("masukjam").where("masukjam",">",gdate).where("masukjam","<",ldate);
};

const create = (data) => {
  return db.add(data);
};

const update = (id, value) => {
  return db.doc(id).update(value);
};

const remove = (id) => {
  return db.doc(id).delete();
};

const TrafficService = {
  getTraffic,
  create,
  update,
  remove
};

export default TrafficService;