import {firestore} from "../firebase";

const db = firestore.collection("/pelaporan");

const getLaporan = (gdate,ldate) => {
  return db.orderBy("tanggal").where("tanggal",">",gdate).where("tanggal","<",ldate);
};

const create = (data) => {
  return db.add(data);
};

const update = (id, value) => {
  return db.doc(id).update(value);
};

const remove = (id) => {
  return db.doc(id).delete();
};

const PelaporanService = {
  getLaporan,
  create,
  update,
  remove
};

export default PelaporanService;