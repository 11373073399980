import React, { useState } from "react";
import { Link } from "react-router-dom";
import KunjunganDataService from "../../Services/KunjunganService";
import { storage } from "../../firebase"
import { Image } from 'react-bootstrap';
import Swal from 'sweetalert2'


const PenggunaDetail = (props) => {
  const initialKunjunganState = {
    key: null,
    alamatpengunjung: "",
    daftarjam: {
      nanoseconds: "",
      seconds: "",
    },
    fotopengunjung: "",
    fotowbp: "",
    hubungan: "",
    kamar: "",
    kasus: "",
    namabinwbp:"",
    namapengunjung:"",
    namawbp:"",
    noidwbp:"",
    noktp:"",
    pengikut1:"",
    pengikut2:"",
    pengikut3:"",
    sudahkunjungan: Boolean,
  };
  const [currentKunjungan, setCurrentKunjungan] = useState(initialKunjunganState);
  const [progress, setProgress] = useState(0);
  const [imageHashToRefresh, setImageHashToRefresh] = useState('');

  const { kunjungan } = props;
  if (currentKunjungan.id !== kunjungan.id) {
    setCurrentKunjungan(kunjungan);
  }

  const convertDate = (secs) => {
    const date = new Date(secs*1000)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    var t = date.toLocaleDateString("id-ID", options)
    return t;
  }

  function showMessage (m) {
    Swal.fire({
      text: m,
      icon: 'info',
    }).then(()=>{
      props.refreshList()
    })
  };

  function getFotoName (link) {
    var fotolink = storage.refFromURL(link);
    return fotolink.name;
  };

  const sudahKunjunganOpt = [
    { value: true, label: 'Sudah' },
    { value: false, label: 'Belum' },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentKunjungan({ ...currentKunjungan, [name]: value });
  };

  async function gantiFotoDiri () {
    const { value: file } = await Swal.fire({
      title: 'Pilih Foto Diri',
      text: 'Foto akan secara otomatis tersimpan setelah diupload',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Upload',
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Upload foto diri'
      }
    })
    
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        e.preventDefault();
        const uploadTask = storage.ref(`/fotouser/diri/${getFotoName(currentKunjungan.fotodiri)}`).put(file);
        uploadTask.on(
          "state_changed",
          snapshot => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progress);
          },
          error => {
            console.log(error);
          },
          () => {
            Swal.fire({
              icon: 'success',
              title: 'Foto diri berhasil diganti',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              setImageHashToRefresh(new Date().getTime())
            });
          }
        );
      }
      reader.readAsDataURL(file)
    }
  };

  const updatePengguna = () => {
    Swal.fire({
      title: 'Ubah data?',
      text: "data akan diperbarui sesuai informasi saat ini",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          pengikut1: currentKunjungan.pengikut1,
          pengikut2: currentKunjungan.pengikut2,
          pengikut3: currentKunjungan.pengikut3,
          sudahkunjungan: currentKunjungan.sudahkunjungan,
        };
        KunjunganDataService.update(currentKunjungan.id, data)
          .then(() => {
            showMessage("Data kunjungan berhasil diubah");
          })
          .catch((e) => {
            console.log(e);
          });
      }
    })
  };

  const deletePengguna = () => {
    Swal.fire({
      title: 'Yakin ingin menghapus?',
      text: "data yang terhapus tidak dapat dikembalikan!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus'
    }).then((result) => {
      if (result.isConfirmed) {
        KunjunganDataService.remove(currentKunjungan.id)
        .then(() => {
          props.refreshList()
        })
        .catch((e) => {
          console.log(e)
          showMessage(e)
      });
      }
    })
  };

  return (
    <div className='mx-10'>
        {currentKunjungan ? (
        <div className="edit-form">
          <h1 className="text-3xl mb-2 text-center font-bold">Detail Kunjungan</h1>
          <form>
          <div className="form-group">
            <p>Tanggal Mendaftar : {convertDate(currentKunjungan.daftarjam.seconds)}</p>
          </div>

          <div className="form-group">
              <label htmlFor="sudahkunjungan">Sudah Kunjungan</label>
              <select className="form-control" id="sudahkunjungan" name="sudahkunjungan"
                value={currentKunjungan.sudahkunjungan} onChange={handleInputChange}>
                {sudahKunjunganOpt.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="pengikut1">Pengikut 1</label>
              <input
                type="text"
                className="form-control"
                id="pengikut1"
                name="pengikut1"
                value={currentKunjungan.pengikut1}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="pengikut2">Pengikut 2</label>
              <input
                type="text"
                className="form-control"
                id="pengikut2"
                name="pengikut2"
                value={currentKunjungan.pengikut2}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="pengikut3">Pengikut 3</label>
              <input
                type="text"
                className="form-control"
                id="pengikut3"
                name="pengikut3"
                value={currentKunjungan.pengikut3}
                onChange={handleInputChange}
              />
            </div>

          <div className="form-group">
              <label htmlFor="noktp">Nomor KTP</label>
              <input
                type="text"
                className="form-control"
                id="noktp"
                name="noktp"
                value={currentKunjungan.noktp}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="nama">Nama Pengunjung</label>
              <input
                type="text"
                className="form-control"
                id="nama"
                name="nama"
                value={currentKunjungan.namapengunjung}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="alamat">Alamat</label>
              <textarea
                rows="4"
                className="form-control"
                id="alamat"
                name="alamat"
                value={currentKunjungan.alamatpengunjung}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="fotodiri">Foto Pengunjung</label>
              <div className="hovereffect">
                <Image id="fotodiri" name="fotodiri" src={`${currentKunjungan.fotopengunjung}?t=${imageHashToRefresh}`} rounded />
                <div className="overlay">
				          <p><a href={currentKunjungan.fotopengunjung} target="_blank" rel="noreferrer">Lihat di Tab Baru</a></p> 
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="fotoktp">Foto WBP Pengunjung</label>
              <div className="hovereffect">
                <Image id="fotoktp" name="fotoktp" src={`${currentKunjungan.fotowbp}?t=${imageHashToRefresh}`} rounded />
                <div className="overlay">
				          <p><a href={currentKunjungan.fotowbp} target="_blank" rel="noreferrer">Lihat di Tab Baru</a></p> 
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="kamar">Blok /Kamar</label>
              <input
                type="text"
                className="form-control"
                id="kamar"
                name="kamar"
                value={currentKunjungan.kamar}
                disabled={true}
              />
            </div>

        </form>

          <button className="btn btn-danger mr-2" onClick={deletePengguna}>
            Hapus Data
          </button>

          <button className="btn btn-success mr-2" onClick={updatePengguna}>
            Simpan Perubahan
          </button>
          
        </div>
      ) : (
        <div>
          <br/>
          <p><strong>Pilih salah satu data untuk melihat detailnya...</strong></p>
        </div>
      )}
    </div>
  );
};

export default PenggunaDetail;