import React, {useState} from "react";
import { Link, Switch, Route   } from "react-router-dom";
import { auth, firestore } from "../firebase";
import { Image } from 'react-bootstrap';
import PasswordReset from "./PasswordReset";


const SignIn = () => {

    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const signInWithEmailAndPasswordHandler = (event, username, password) => {
        event.preventDefault();
        setError(null)
        if (username === "" || password === "") {
          setError("Masih ada kolom yang kosong!");
        }else{
          auth.signInWithEmailAndPassword(email, password).then((loggedin) => {
            if (loggedin != null) {
              return window.location.replace("/");
            } else {
              console.log("Gagal login, periksa jaringan")
            }
          }).catch(error => {
            setError(error.message);
            console.error("Error signing in", error);
          });
        }
      };
      
      const onChangeHandler = (event) => {
          const {name, value} = event.currentTarget;
          if(name === 'adminUsername') {
              setUsername(value);
              firestore.collection('admin')
              .where('displayName','==', value)
              .get().then(querySnapshot => {
                querySnapshot.forEach(doc => {
                  setEmail(doc.data().email);
                })
              })
          }
          else if(name === 'adminPassword'){
            setPassword(value);
          }
      };
   

  return (
    <div>
      <Image src={process.env.PUBLIC_URL + '/logo512.png'} width='15%' height='auto' className="rounded mx-auto d-block" roundedCircle/>
      <h1 className="text-3xl mb-2 text-center font-bold">Silahkan Masuk</h1>
      <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
        {error !== null && <div className = "py-4 bg-red-600 w-full text-white text-center mb-3">{error}</div>}
        <form className="">
          <label htmlFor="adminUsername" className="block">
            Username :
          </label>
          <input
            type="text"
            className="my-1 p-1 w-full"
            name="adminUsername"
            value = {username}
            placeholder="Masukkan Username"
            id="adminUsername"
            onChange = {(event) => onChangeHandler(event)}
            required/>
          <label htmlFor="adminPassword" className="block">
            Password :
          </label>
          <input
            type="password"
            className="mt-1 mb-3 p-1 w-full"
            name="adminPassword"
            value = {password}
            placeholder="Masukkan Password"
            id="adminPassword"
            onChange = {(event) => onChangeHandler(event)}
            required/>
          <button className="bg-green-400 hover:bg-green-500 w-full py-2 text-white" onClick = {(event) => {signInWithEmailAndPasswordHandler(event, username, password)}}>
            Masuk
          </button>
        </form>
        <p className="text-center my-3">
          <br />{" "}
          <Link to="/resetpassword" className="text-blue-500 hover:text-blue-600">
            Lupa Password?
          </Link>
        </p>
        <Switch>
          <Route path="/resetpassword">
            <PasswordReset />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default SignIn;
