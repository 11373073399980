import {firestore} from "../firebase";

const db = firestore.collection("/kunjungan");

const getRegistered = (gdate,ldate) => {
  return db.orderBy("daftarjam").where("daftarjam",">",gdate).where("daftarjam","<",ldate);
};

const create = (data) => {
  return db.add(data);
};

const update = (id, value) => {
  return db.doc(id).update(value);
};

const remove = (id) => {
  return db.doc(id).delete();
};

const KunjunganService = {
  getRegistered,
  create,
  update,
  remove
};

export default KunjunganService;