import React, { useState } from "react";
import PenggunaDataService from "../../Services/PenggunaService";
import { storage } from "../../firebase"
import { Image } from 'react-bootstrap';
import Swal from 'sweetalert2'


const PermohonanPenggunaDetail = (props) => {
  const initialPenggunaState = {
    key: null,
    alamat: "",
    fotodiri: "",
    fotoktp: "",
    jeniskelamin: "",
    nama: "",
    statusakun: "",
    sudahaktif: Boolean,
    telp: "",
    tgldaftar: {
      nanoseconds: "",
      seconds: "",
    },
  };
  const [currentPengguna, setCurrentPengguna] = useState(initialPenggunaState);
  const [progress, setProgress] = useState(0);
  const [imageHashToRefresh, setImageHashToRefresh] = useState('');

  const { pengguna } = props;
  if (currentPengguna.id !== pengguna.id) {
    setCurrentPengguna(pengguna);
  }

  const convertDate = (secs) => {
    const date = new Date(secs*1000)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    var t = date.toLocaleDateString("id-ID", options)
    return t;
  }

  function showMessage (m) {
    Swal.fire({
      text: m,
      icon: 'info',
    }).then(()=>{
      props.refreshList();
    })
  };

  function getFotoName (link) {
    var fotolink = storage.refFromURL(link);
    return fotolink.name;
  };

  const jkOptions = [
    { value: 'laki-laki', label: 'Laki-laki' },
    { value: 'perempuan', label: 'Perempuan' },
  ];

  const statusakunOptions = [
    { value: 'verified', label: 'Diterima' },
    { value: 'refused', label: 'Ditolak' },
    { value: 'revision', label: 'Perlu Revisi' },
    { value: 'proccess', label: 'Sedang Diproses' },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentPengguna({ ...currentPengguna, [name]: value })
  };

  async function gantiFotoDiri () {
    const { value: file } = await Swal.fire({
      title: 'Pilih Foto Diri',
      text: 'Foto akan secara otomatis tersimpan setelah diupload',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Upload',
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Upload foto diri'
      }
    })
    
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        e.preventDefault();
        const uploadTask = storage.ref(`/fotouser/diri/${getFotoName(currentPengguna.fotodiri)}`).put(file);
        uploadTask.on(
          "state_changed",
          snapshot => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progress);
          },
          error => {
            console.log(error);
          },
          () => {
            Swal.fire({
              icon: 'success',
              title: 'Foto diri berhasil diganti',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              setImageHashToRefresh(new Date().getTime())
            });
          }
        );
      }
      reader.readAsDataURL(file)
    }
  };

  async function gantiFotoKTP () {
    const { value: file } = await Swal.fire({
      title: 'Pilih Gambar KTP',
      text: 'Foto akan secara otomatis tersimpan setelah diupload',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Upload',
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Upload foto KTP'
      }
    })
    
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        e.preventDefault();
        const uploadTask = storage.ref(`/fotouser/ktp/${getFotoName(currentPengguna.fotoktp)}`).put(file);
        uploadTask.on(
          "state_changed",
          snapshot => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progress);
          },
          error => {
            console.log(error);
          },
          () => {
            Swal.fire({
              icon: 'success',
              title: 'Foto KTP berhasil diganti',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              setImageHashToRefresh(new Date().getTime())
            });
          }
        );
      }
      reader.readAsDataURL(file)
    }
  };

  const updatePengguna = () => {
    var sudahaktif = false;
    var statusakun = currentPengguna.statusakun;
    if(statusakun === "verified"){
      sudahaktif = true
    }else{
      sudahaktif = false;
    }
    return exeUpdatePengguna(statusakun, sudahaktif)
  };

  function exeUpdatePengguna (statusakun, sudahaktif) {
    setCurrentPengguna({ ...currentPengguna, sudahaktif: sudahaktif })
    Swal.fire({
      title: 'Ubah data?',
      text: "data akan diperbarui sesuai informasi saat ini",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          alamat: currentPengguna.alamat,
          jeniskelamin: currentPengguna.jeniskelamin,
          nama: currentPengguna.nama,
          statusakun: statusakun,
          sudahaktif: sudahaktif,
          telp: currentPengguna.telp,
        };
        PenggunaDataService.update(currentPengguna.id, data)
          .then(() => {
            showMessage("Data pengguna berhasil diubah");
          })
          .catch((e) => {
            console.log(e);
          });
      }
    })
  };

  const deletePengguna = () => {
    Swal.fire({
      title: 'Yakin ingin menghapus?',
      text: "data yang terhapus tidak dapat dikembalikan!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus'
    }).then((result) => {
      if (result.isConfirmed) {
        PenggunaDataService.remove(currentPengguna.id)
        .then(() => {
          props.refreshList()
        })
        .catch((e) => {
          console.log(e)
          showMessage(e)
      });
      }
    })
  };

  return (
    <div className='mx-10'>
        {currentPengguna ? (
        <div className="edit-form">
          <h1 className="text-3xl mb-2 text-center font-bold">Detail Data Pemohon</h1>
          <form>
          <div className="form-group">
              <label htmlFor="noktp">Nomor KTP</label>
              <input
                type="text"
                className="form-control"
                id="noktp"
                name="noktp"
                value={currentPengguna.id}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="nama">Nama Lengkap</label>
              <input
                type="text"
                className="form-control"
                id="nama"
                name="nama"
                value={currentPengguna.nama}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="jeniskelamin">Jenis Kelamin</label>
              <select className="form-control" id="jeniskelamin" name="jeniskelamin"
                value={currentPengguna.jeniskelamin} onChange={handleInputChange}>
                {jkOptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="alamat">Alamat</label>
              <textarea
                rows="4"
                className="form-control"
                id="alamat"
                name="alamat"
                value={currentPengguna.alamat}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="telp">Nomor Telepon</label>
              <input
                type="number"
                className="form-control"
                id="telp"
                name="telp"
                value={currentPengguna.telp}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="fotodiri">Foto Diri</label>
              <div className="hovereffect">
                <Image id="fotodiri" name="fotodiri" src={`${currentPengguna.fotodiri}?t=${imageHashToRefresh}`} rounded />
                <div className="overlay">
                  <h2><a onClick={gantiFotoDiri} role="button">Ganti Foto Diri</a></h2>
				          <p><a href={currentPengguna.fotodiri} target="_blank" rel="noreferrer">Lihat di Tab Baru</a></p> 
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="fotoktp">Foto KTP</label>
              <div className="hovereffect">
                <Image id="fotoktp" name="fotoktp" src={`${currentPengguna.fotoktp}?t=${imageHashToRefresh}`} rounded />
                <div className="overlay">
                  <h2><a role="button" onClick={gantiFotoKTP}>Ganti Foto KTP</a></h2>
				          <p><a href={currentPengguna.fotoktp} target="_blank" rel="noreferrer">Lihat di Tab Baru</a></p> 
                </div>
              </div>
            </div>

            <div className="form-group">
              <p>Tanggal Mendaftar : {convertDate(currentPengguna.tgldaftar.seconds)}</p>
            </div>

            <div className="form-group">
              <label htmlFor="statusakun">Status Akun</label>
              <select className="form-control" id="statusakun" name="statusakun"
                value={currentPengguna.statusakun} onChange={handleInputChange}>
                {statusakunOptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>

        </form>

          <button className="btn btn-danger mr-2" onClick={deletePengguna}>
            Hapus
          </button>

          <button className="btn btn-success mr-2" onClick={updatePengguna}>
            Simpan Perubahan
          </button>
        </div>
      ) : (
        <div>
          <br/>
          <p><strong>Pilih salah satu data untuk melihat detailnya...</strong></p>
        </div>
      )}
    </div>
  );
};

export default PermohonanPenggunaDetail;