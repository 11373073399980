import {firestore} from "../firebase";

const db = firestore.collection("/user");

const getVerifiedAccount = (searchvalue) => {
  return db.orderBy("nama", "asc").where("sudahaktif","==",true);
};

const getRequestingAccount = (searchvaluee) => {
  return db.orderBy("nama","asc").where("sudahaktif","==",false);
};

const create = (data) => {
  return db.add(data);
};

const update = (id, value) => {
  return db.doc(id).update(value);
};

const remove = (id) => {
  return db.doc(id).delete();
};

const PenggunaService = {
  getVerifiedAccount,
  getRequestingAccount,
  create,
  update,
  remove
};

export default PenggunaService;