import React, { useState, useEffect, useRef } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import KirangDataService from "../../Services/KirangService";
import Navigation from "../Navigation"
import { firestore } from "../../firebase";
import { Image } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { useReactToPrint } from 'react-to-print';

function ProsesKirang() {
  const initialKirangState = {
    key: null,
    id: "",
    namapengirim: "",
    fotowbp: "",
    alamatpengirim: "",
    kasus: "",
    rincianbarang: "",
    hubungan: "",
    namawbp: "",
    namabinwbp: "",
    tglterkirim: {
      nanoseconds: "",
      seconds: "",
    },
    tgldaftar: {
      nanoseconds: "",
      seconds: "",
    },
    fotopengirim: "",
    status: "",
    kamar: "",
    noktp: "",
    noidwbp: "",
  };
  const [idikirimannya, setIdKirimannya] = useState(null);
  const [currentKirang, setCurrentKirang] = useState(initialKirangState);
  const [printed, setPrinted] = useState(false);
  const [imageHashToRefresh, setImageHashToRefresh] = useState(new Date().getTime());
  const componentRef = useRef();
  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

    return [ htmlElRef, setFocus ] 
  }
  const [inputIdKirangRef, SetInputIdKirangFocus] = useFocus();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [value, loading, error] = useDocument(
  firestore.doc(`/kirimbarang/${idikirimannya}`),
  {
      snapshotListenOptions: { includeMetadataChanges: true },
  });

  function showMessage (m) {
    Swal.fire({
      text: m,
      icon: 'info',
    }).then(()=>{
      return false;
    })
  };

  const convertDate = (secs) => {
    const date = new Date(secs*1000)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    var t = date.toLocaleDateString("id-ID", options)
    return t;
  }

  const handleIdChange = (event) => {
    event.preventDefault();
    var l = event.target.elements.idkiriman.value.length;
    if(l>0){
      setIdKirimannya(event.target.elements.idkiriman.value);
    }else{setIdKirimannya(null);}
  };

  const setKirang = () => {
    if (currentKirang==null) {setCurrentKirang(value.data());} else {return false;}
  };

  useEffect(() => {
    if(!loading){setKirang();}else{return false;}
  });

  const handleReset = () => {
    SetInputIdKirangFocus();setIdKirimannya(null);setPrinted(false);
    setTimeout(function() {
      setCurrentKirang(null);
   }, 300);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentKirang({ ...currentKirang, [name]: value });
    setImageHashToRefresh(new Date().getTime());
  };

  function printKirang () {
    const data = {status: 'terkirim', 
    tglterkirim:new Date()};
    KirangDataService.update(idikirimannya, data)
      .then(() => {
        handlePrint();
        setTimeout(function() {handlePrint();}, 2000);
      })
      .catch((e) => {
        console.log(e);
        showMessage(e);
      });
  };

  function updateKirang () {
    Swal.fire({
      title: 'Ubah data?',
      text: "data akan diperbarui sesuai informasi saat ini",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          kamar: currentKirang.kamar,
          rincianbarang: currentKirang.rincianbarang
        };
        KirangDataService.update(idikirimannya, data)
          .then(() => {
            showMessage("Data kiriman berhasil diubah");
          })
          .catch((e) => {
            console.log(e);
            showMessage(e);
          });
      }
    })
  };

  const deleteKirang = () => {
    Swal.fire({
      title: 'Yakin ingin menghapus?',
      text: "data yang terhapus tidak dapat dikembalikan!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus'
    }).then((result) => {
      if (result.isConfirmed) {
        KirangDataService.remove(idikirimannya)
        .then(() => {
          handleReset();
        })
        .catch((e) => {
          console.log(e);
          showMessage(e);
      });
      }
    })
  };

  return(
      <div>
        <Navigation />  
        <div className="list row mt-20 mx-10">
        <div className="col-md-10">
        <h1 className="text-3xl mb-2 text-center font-bold">Proses Kiriman Obat</h1>
        <div className="form-group">
          <form onSubmit={handleIdChange}>
          <label htmlFor="idkiriman">ID Kiriman Obat</label>
            <input
              autoFocus
              required
              maxLength="20"
              autoComplete="off"
              type="text"
              placeholder="Masukkan manual atau gunakan scanner"
              className="form-control"
              id="idkiriman"
              name="idkiriman"
              onFocus = {(e) => e.target.value = ""}
              ref= {inputIdKirangRef}
            /><br/>
            <input type="submit" className="btn btn-primary" onClick={handleReset}/><input type="reset" className="btn btn-warning" onClick={handleReset}/>
            </form>
        </div><hr/><br/>
        <div>
      <p>
        <strong>Detail Kiriman Obat :</strong><br/>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}{loading && <span>Mengambil data...</span>}
        </p>
        <form>
            {idikirimannya!==null&&currentKirang!=null&&
            <div className="form-group">
              <br/><strong>Didaftarkan Pada : {convertDate(currentKirang.tgldaftar.seconds)}</strong>
            </div>}

          {idikirimannya!==null&&currentKirang!=null&&
          <div className="form-group">
              <label htmlFor="namapengirim">Pengirim</label>
              <input
                type="text"
                className="form-control"
                id="namapengirim"
                name="namapengirim"
                value={currentKirang.namapengirim+' | KTP : '+currentKirang.noktp}
                disabled={true}
              />
            </div>}

          {idikirimannya!==null&&currentKirang!=null&&
          <div className="form-group">
              <label htmlFor="alamatpengirim">Alamat Pengirim</label>
              <textarea
                rows="2"
                className="form-control"
                id="alamatpengirim"
                name="alamatpengirim"
                value={currentKirang.alamatpengirim}
                disabled={true}
              />
            </div>}

            {idikirimannya!==null&&currentKirang!=null&&
            <div className="form-group">
              <label htmlFor="hubungan">Hubungan dengan WBP</label>
              <input
                type="text"
                className="form-control"
                id="hubungan"
                name="hubungan"
                value={currentKirang.hubungan}
                disabled={true}
              />
            </div>}

            {idikirimannya!==null&&currentKirang!=null&&
            <div className="form-group">
              <label htmlFor="namawbp">WBP Yang Dikirimi</label>
              <input
                type="text"
                className="form-control"
                id="namawbp"
                name="namawbp"
                value={currentKirang.namawbp+' Bin '+currentKirang.namabinwbp}
                disabled={true}
              />
            </div>}

            {idikirimannya!==null&&currentKirang!=null&&
            <div className="form-group">
              <label htmlFor="noidwbp">Nomor Berkas WBP</label>
              <input
                type="text"
                className="form-control"
                id="noidwbp"
                name="noidwbp"
                value={currentKirang.noidwbp}
                disabled={true}
              />
            </div>}

            {idikirimannya!==null&&currentKirang!=null&&
            <div className="form-group">
              <label htmlFor="kamar">Kamar</label>
              <input
                type="text"
                maxLength="3"
                className="form-control"
                id="kamar"
                name="kamar"
                value={currentKirang.kamar}
                onChange={handleInputChange}
              />
            </div>}

            {idikirimannya!==null&&currentKirang!=null&&
            <div className="form-group">
              <label htmlFor="rincianbarang">Ringkasan Isi Obat</label>
              <textarea
                rows="2"
                type="text"
                className="form-control"
                id="rincianbarang"
                name="rincianbarang"
                value={currentKirang.rincianbarang}
                onChange={handleInputChange}
              />
            </div>}

            <div className="container">
            <div className="row">
            <div className="col">
            {idikirimannya!==null&&currentKirang!=null&&
            <div className="form-group">
              <label htmlFor="fotopengirim">Foto Pengirim</label>
              <div className="hovereffect">
                <Image id="fotopengirim" name="fotopengirim" src={`${currentKirang.fotopengirim}?t=${imageHashToRefresh}`} rounded />
                <div className="overlay">
				          <p><a href={currentKirang.fotopengirim} target="_blank" rel="noreferrer">Lihat di Tab Baru</a></p> 
                </div>
              </div>
            </div>}
            </div>
            <div className="col">
            {idikirimannya!==null&&currentKirang!=null&&
            <div className="form-group">
              <label htmlFor="fotowbp">Foto WBP</label>
              <div className="hovereffect">
                <Image id="fotowbp" name="fotowbp" src={`${currentKirang.fotowbp}?t=${imageHashToRefresh}`} onError={(e) => { e.target.src = process.env.PUBLIC_URL+'/avatar-wbp.png'}} rounded />
                <div className="overlay">
				          <p><a href={currentKirang.fotowbp} target="_blank" rel="noreferrer">Lihat di Tab Baru</a></p> 
                </div>
              </div>
            </div>}
            </div>
            </div>
            </div>
        </form>
        <hr/>
        {idikirimannya!==null&&currentKirang!=null&&!printed&& (() => {setTimeout(function() {
        printKirang();setPrinted(true);}, 300);})()}
        {idikirimannya!==null&&currentKirang!=null&&
        <button className="btn btn-secondary mr-2" onClick={printKirang} >
          Cetak
        </button>}

        {idikirimannya!==null&&currentKirang!=null&&
        <button className="btn btn-success mr-2" onClick={updateKirang}>
          Simpan Perubahan
        </button>}

        {idikirimannya!==null&&currentKirang!=null&&
        <button className="btn btn-danger mr-2" onClick={deleteKirang}>
          Hapus Kiriman
        </button>}

        <div style={{display:'none', height:'0px'}}>
        {idikirimannya!==null&&currentKirang!=null&&
          <div ref={componentRef}>
            <p style={{textAlign: 'center'}}>
              <img style={{display: 'block', width: '20%', height: 'auto', marginLeft:'auto', marginRight:'auto'}} src={process.env.PUBLIC_URL + '/logo_kemenkumham.png'} alt="logo kemenkumham" />
              <span style={{display: 'block', fontSize:'150%'}}><strong>LEMBAGA PEMASYARAKATAN KELAS I SEMARANG</strong></span><span style={{display: 'block',fontSize:'140%'}}><strong>JL. RAYA SEMARANG-BOJA KM.4, SEMARANG 50188 Telp (024) 76433499</strong></span>
            </p>
            <p style={{textAlign: 'center'}}><span style={{textDecoration: 'underline', fontSize:'250%'}}>KIRIMAN OBAT - {convertDate(Math.floor(new Date().getTime()/1000))}</span></p>
                  <table style={{fontSize:'300%'}}>
                      <tbody>
                          <tr>
                            <td style={{width: '19%'}}>Nama Pengirim</td>
                            <td style={{width: '1%'}}>:&nbsp;</td>
                            <td style={{width: '80%'}}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{width:'100%',textAlign:'left', fontSize:'130%'}}>{currentKirang.namapengirim}</td><td/><td/>
                          </tr>
                          <tr>
                            <td style={{width: '19%'}}>KTP</td>
                            <td style={{width: '1%'}}>:&nbsp;</td>
                            <td style={{width: '80%'}}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{width:'100%',textAlign:'left', fontSize:'130%'}}>{currentKirang.noktp}</td><td/><td/>
                          </tr>
                          <tr>
                            <td style={{width: '19%'}}>Alamat</td>
                            <td style={{width: '1%'}}>:&nbsp;</td>
                            <td style={{width: '80%'}}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{width:'100%',textAlign:'left', fontSize:'130%'}}>{currentKirang.alamatpengirim}</td><td/><td/>
                          </tr>
                      </tbody>
                    </table><br/>
                    <table style={{width: '100%', borderWidth:"2px", borderColor:"#000000", borderStyle:'solid', fontSize:'300%'}}>
                      <tbody>
                        <tr>
                          <td style={{textAlign: 'center', height: '18px'}}><strong>Ringkasan Isi Obat</strong></td>
                        </tr>
                        <tr>
                          <td><p style={{marginLeft: '1%'}}>{currentKirang.rincianbarang}</p></td>
                        </tr>
                      </tbody>
                    </table><br/>
            <table style={{width: '100%', fontSize:'300%'}}>
              <tbody>
                <tr>
                  <td style={{width: '19%'}}>WBP Penerima</td>
                  <td style={{width: '1%'}}>:&nbsp;</td>
                  <td style={{width: '80%'}}>&nbsp;</td>
                </tr>
                <tr>
                  <td style={{width:'100%',textAlign:'left', fontSize:'130%'}}>{currentKirang.namawbp} bin {currentKirang.namabinwbp}</td><td/><td/>
                </tr>
                <tr>
                  <td style={{width: '19%'}}>Perkara</td>
                  <td style={{width: '1%'}}>:&nbsp;</td>
                  <td style={{width: '80%'}}>&nbsp;</td>
                </tr>
                <tr>
                  <td style={{width:'100%',textAlign:'left', fontSize:'130%'}}>{currentKirang.kasus}</td><td/><td/>
                </tr>
                <tr>
                  <td style={{width: '19%'}}>Blok / Kamar Hunian</td>
                  <td style={{width: '1%'}}>:&nbsp;</td>
                  <td style={{width: '80%'}}>&nbsp;</td>
                </tr>
                <tr>
                  <td style={{width:'100%',textAlign:'left', fontSize:'150%'}}>{currentKirang.kamar}</td><td/><td/>
                </tr>
              </tbody>
            </table><br/>
          </div>}
          </div>
        </div>
    </div>
    </div>
    </div>
  );
}


export default ProsesKirang;