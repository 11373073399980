import React, { useContext } from "react";
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { AuthContext } from "../Services/Authentication";

const Navigation = () => {
    const thiscontext = useContext(AuthContext);
    return(
        <>
            <Navbar collapseOnSelect fixed='top' expand='sm' bg='dark' variant='dark'>
                <Container>
                    <Navbar.Brand href="/dashboard"><img src={process.env.PUBLIC_URL + '/logo192.png'} alt="logo" width="50" height="50" className="d-inline-block align-center"/>{' '}LP Semarang</Navbar.Brand>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav>
                            <NavDropdown title="Admin" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/addadmin">Tambah Admin</NavDropdown.Item>
                                <NavDropdown.Item href="/admins">Kelola Admin</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Pengguna" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/users">Kelola Akun Pengguna</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Permohonan" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/paps">Permohonan Akun Pengguna</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/phws">Permohonan Hubung ke WBP</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/permohkellist">Permohonan Keluarga Pengikut</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Layanan" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/ss">Self Services</NavDropdown.Item>
                                <NavDropdown title={<span className="text-center text-dark">Kunjungan</span>} id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/kunjungan">Proses Kunjungan</NavDropdown.Item>
                                    <NavDropdown.Item href="/riwkunjungan">Riwayat Kunjungan</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown.Divider />
                                <NavDropdown title={<span className="text-center text-dark">Kiriman Obat</span>} id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/prokb">Proses Kiriman Obat</NavDropdown.Item>
                                    <NavDropdown.Item href="/riwkb">Riwayat Kiriman Obat</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown.Divider />
                                <NavDropdown title={<span className="text-center text-dark">Berita Lapas</span>} id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/addnews">Tambah Berita</NavDropdown.Item>
                                    <NavDropdown.Item href="/newss">Kelola Berita</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/sk">Survei Kepuasan</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/pelaporan">Pelaporan</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/chat">Layanan Informasi</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Petugas" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/addpetugas">Tambah Petugas</NavDropdown.Item>
                                <NavDropdown.Item href="/petugass">Kelola Petugas</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Wasrik dan Pengamanan" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/traffic">Data E Traffic</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/control">Data E Control</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            <a href="/profile">{thiscontext.currentUser.displayName}</a> •
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Navigation;