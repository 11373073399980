import {firestore} from "../firebase";

const getControl = (col,gdate,ldate) => {
  return firestore.collection(col).orderBy("jamkontrol").where("jamkontrol",">",gdate).where("jamkontrol","<",ldate);
};

const create = (col, data) => {
  return firestore.collection(col).add(data);
};

const update = (col,id,value) => {
  return firestore.collection(col).doc(id).update(value);
};

const remove = (col, id) => {
  return firestore.collection(col).doc(id).delete();
};

const TrafficService = {
  getControl,
  create,
  update,
  remove
};

export default TrafficService;