import React, { useState } from "react";
import KeluargaDataService from "../../Services/KeluargaService";
import { Image } from 'react-bootstrap';
import Swal from 'sweetalert2'


const PermohonanKeluargaDetail = (props) => {
  const initialPermohonanState = {
    key: null,
    nama:"",
    fotokk:"",
  };
  const [currentPermohonan, setCurrentPermohonan] = useState(initialPermohonanState);
  const [imageHashToRefresh, setImageHashToRefresh] = useState('');

  const { permohonan } = props;
  if (currentPermohonan.id !== permohonan.id) {
    setCurrentPermohonan(permohonan);
  }

  function showMessage (m) {
    Swal.fire({
      text: m,
      icon: 'info',
    }).then(()=>{
      props.refreshList();
    })
  };

  const deletePermohonan = () => {
    Swal.fire({
      title: 'Yakin ingin menghapus?',
      text: "data yang terhapus tidak dapat dikembalikan!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus'
    }).then((result) => {
      if (result.isConfirmed) {
        KeluargaDataService.delfoto(currentPermohonan.fotokk)
        .then(() => {
          KeluargaDataService.removeP(currentPermohonan.id)
          .then(()=>{
            props.refreshList();
          })
        })
        .catch((e) => {
          console.log(e)
          showMessage(e)
      });
      }
    })
  };

  return (
    <div className='mx-10'>
        {currentPermohonan ? (
        <div className="edit-form">
          <h1 className="text-3xl mb-2 text-center font-bold">Detail Data Permohonan</h1>
          <form>
          <div className="form-group">
              <label htmlFor="namapem">Nama Pemohon</label>
              <input
                type="text"
                className="form-control"
                id="namapem"
                name="namapem"
                value={currentPermohonan.nama}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="fotokk">Foto Kartu Keluarga (KK)</label>
              <div className="hovereffect">
                <Image id="fotokk" name="fotokk" src={`${currentPermohonan.fotokk}?t=${imageHashToRefresh}`} rounded />
                <div className="overlay">
				          <p><a href={currentPermohonan.fotokk} target="_blank" rel="noreferrer">Lihat di Tab Baru</a></p> 
                </div>
              </div>
            </div>

        </form>

          <button className="btn btn-danger mr-2" onClick={deletePermohonan}>
            Sudah Ditindaklanjuti (Hapus)
          </button>
        </div>
      ) : (
        <div>
          <br/>
          <p><strong>Pilih salah satu data untuk melihat detailnya...</strong></p>
        </div>
      )}
    </div>
  );
};

export default PermohonanKeluargaDetail;