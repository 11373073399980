import React, { useEffect, useState } from "react";
import { auth, generateAdminDocument } from "../firebase";
import Spinner from 'react-spinner-material';

export const AuthContext = React.createContext();
    export const AuthProvider = ({ children }) => {
      const [currentUser, setCurrentUser] = useState(null);
      const [pending, setPending] = useState(true);
      useEffect(() => {
        auth.onAuthStateChanged(async userAuth => {
            const loggedInUser =  await generateAdminDocument(userAuth)
            setCurrentUser(loggedInUser)
            setPending(false)
        });
      }, []);
      if(pending){
        return <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}} ><Spinner size={120} spinnercolor={"#333"} spinnerwidth={2} visible={true} /></div>
      }
      return (
        <AuthContext.Provider value={{currentUser}}>
          {children}
        </AuthContext.Provider>
      );
    };