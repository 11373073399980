import React, { useState } from "react";
import WbpDataService from "../../Services/WbpService";
import { storage } from "../../firebase"
import { Image } from 'react-bootstrap';
import Swal from 'sweetalert2'


const WbpDetail = (props) => {
  const initialWbpState = {
    key: null,
    fotowbp: "",
    hubungan: "",
    kamar: "",
    kasus: "",
    ktpuser: "",
    namabinwbp: "",
    namauser:"",
    namawbp: "",
    noidwbp: "",
    statuspenambahan: "",
  };
  const [currentWbp, setCurrentWbp] = useState(initialWbpState);
  const [progress, setProgress] = useState(0);
  const [imageHashToRefresh, setImageHashToRefresh] = useState('');

  const { wbp } = props;
  if (currentWbp.id !== wbp.id) {
    setCurrentWbp(wbp);
  }

  function showMessage (m) {
    Swal.fire({
      text: m,
      icon: 'info',
    }).then(()=>{
      window.location.reload();
    })
  };

  function getFotoName (link) {
    var fotolink = storage.refFromURL(link);
    return fotolink.name;
  };

  const statusPermohonanOptions = [
    { value: 'acc', label: 'Diterima' },
    { value: 'proccess', label: 'Sedang Diproses' },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentWbp({ ...currentWbp, [name]: value });
  };

  async function gantiFotoWBP () {
    const { value: file } = await Swal.fire({
      title: 'Pilih Foto WBP',
      text: 'Foto akan secara otomatis tersimpan setelah diupload',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Upload',
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Upload foto wbp'
      }
    })
    
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        e.preventDefault();
        const uploadTask = storage.ref(`/fotouser/wbp/${currentWbp.ktpuser}/${getFotoName(currentWbp.fotowbp)}`).put(file);
        uploadTask.on(
          "state_changed",
          snapshot => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progress);
          },
          error => {
            console.log(error);
          },
          () => {
            Swal.fire({
              icon: 'success',
              title: 'Foto WBP berhasil diganti',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              setImageHashToRefresh(new Date().getTime())
            });
          }
        );
      }
      reader.readAsDataURL(file)
    }
  };

  const updateWbp = () => {
    Swal.fire({
      title: 'Ubah data?',
      text: "data akan diperbarui sesuai informasi saat ini",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          hubungan: currentWbp.hubungan,
          kamar: currentWbp.kamar,
          kasus: currentWbp.kasus,
          namabinwbp: currentWbp.namabinwbp,
          namawbp: currentWbp.namawbp,
          noidwbp: currentWbp.noidwbp,
          statuspenambahan: currentWbp.statuspenambahan,
        };
        WbpDataService.update(currentWbp.ktpuser, currentWbp.id, data)
          .then(() => {
            showMessage("Data wbp berhasil diubah");
          })
          .catch((e) => {
            console.log(e);
          });
      }
    })
  };

  const deleteWbp = () => {
    Swal.fire({
      title: 'Yakin ingin menghapus?',
      text: "data yang terhapus tidak dapat dikembalikan!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus'
    }).then((result) => {
      if (result.isConfirmed) {
        WbpDataService.remove(currentWbp.ktpuser, currentWbp.id)
        .then(() => {
          WbpDataService.delfoto(currentWbp.fotowbp)
            .then(() => {
              window.location.reload();
            })
            .catch((e) => {
              console.log(e)
              showMessage(e)
          });
        })
        .catch((e) => {
          console.log(e)
          showMessage(e)
      });
      }
    })
  };

  return (
    <div className='mx-10'>
        {currentWbp ? (
        <div className="edit-form">
          <h1 className="text-3xl mb-2 text-center font-bold">Detail Permohonan Terhubung WBP</h1>
          <form>
          <div className="form-group">
              <label htmlFor="namauser">Pemohon Untuk Terhubung</label>
              <input
                type="text"
                className="form-control"
                id="namauser"
                name="namauser"
                value={currentWbp.namauser+' | KTP : '+currentWbp.ktpuser}
                disabled={true}
              />
          </div>

          <div className="form-group">
              <label htmlFor="noidwbp">Nomor Berkas WBP</label>
              <input
                type="number"
                className="form-control"
                id="noidwbp"
                name="noidwbp"
                value={parseInt(currentWbp.noidwbp)}
                onChange={handleInputChange}
              />
          </div>

          <div className="form-group">
              <label htmlFor="namawbp">Nama WBP</label>
              <input
                type="text"
                className="form-control"
                id="namawbp"
                name="namawbp"
                value={currentWbp.namawbp}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="namabinwbp">Bin</label>
              <input
                type="text"
                className="form-control"
                id="namabinwbp"
                name="namabinwbp"
                value={currentWbp.namabinwbp}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="kamar">Kamar</label>
              <input
                type="text"
                className="form-control"
                id="kamar"
                name="kamar"
                maxLength="3"
                value={currentWbp.kamar}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="fotowbp">Foto WBP</label>
              <div className="hovereffect">
                <Image id="fotowbp" name="fotowbp" src={`${currentWbp.fotowbp}?t=${imageHashToRefresh}`} onError={(e) => { e.target.src = process.env.PUBLIC_URL+'/avatar-wbp.png'}} rounded />
                <div className="overlay">
                  <h2><a onClick={gantiFotoWBP} role="button">Ganti Foto WBP</a></h2>
				          <p><a href={currentWbp.fotowbp} target="_blank" rel="noreferrer">Lihat di Tab Baru</a></p> 
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="kasus">Kasus</label>
              <input
                type="text"
                className="form-control"
                id="kasus"
                name="kasus"
                value={currentWbp.kasus}
                onChange={handleInputChange} />
            </div>

            <div className="form-group">
              <label htmlFor="hubungan">Hubungan</label>
              <input
                type="text"
                className="form-control"
                id="hubungan"
                name="hubungan"
                value={currentWbp.hubungan}
                onChange={handleInputChange} />
            </div>

            <div className="form-group">
              <label htmlFor="statuspenambahan">Status Permohonan</label>
              <select className="form-control" id="statuspenambahan" name="statuspenambahan"
                value={currentWbp.statuspenambahan} onChange={handleInputChange}>
                {statusPermohonanOptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>

        </form>

          <button className="btn btn-danger mr-2" onClick={deleteWbp}>
            Hapus Permohonan
          </button>

          <button className="btn btn-success mr-2" onClick={updateWbp}>
            Simpan Perubahan
          </button>
          
        </div>
        ) : (
        <div>
          <br/>
          <p><strong>Pilih salah satu data untuk melihat detailnya...</strong></p>
        </div>
      )}
    </div>
  );
};

export default WbpDetail;