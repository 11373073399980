import React, { useState, useEffect, useRef } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import KunjunganDataService from "../../Services/KunjunganService";
import Navigation from "../Navigation"
import { firestore } from "../../firebase";
import { Image } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { useReactToPrint } from 'react-to-print';

function ProsesKunjungan() {
  const initialKunjunganState = {
    key: null,
    alamatpengunjung: "",
    daftarjam: {
      nanoseconds: "",
      seconds: "",
    },
    fotopengunjung: "",
    fotowbp: "",
    hubungan: "",
    kamar: "",
    kasus: "",
    namabinwbp:"",
    namapengunjung:"",
    namawbp:"",
    noidwbp:"",
    noktp:"",
    pengikut1:"",
    pengikut2:"",
    pengikut3:"",
    sudahkunjungan: Boolean,
  };
  const [idkunjungannya, setIdKunjungannya] = useState(null);
  const [currentKunjungan, setCurrentKunjungan] = useState(initialKunjunganState);
  const [printed, setPrinted] = useState(false);
  const [imageHashToRefresh, setImageHashToRefresh] = useState(new Date().getTime());
  const componentRef = useRef();
  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

    return [ htmlElRef, setFocus ] 
  }
  const [inputIdKunjunganRef, SetInputIdKunjunganFocus] = useFocus();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [value, loading, error] = useDocument(
  firestore.doc(`/kunjungan/${idkunjungannya}`),
  {
      snapshotListenOptions: { includeMetadataChanges: true },
  });

  function showMessage (m) {
    Swal.fire({
      text: m,
      icon: 'info',
    }).then(()=>{
      return false;
    })
  };

  const convertDate = (secs) => {
    const date = new Date(secs*1000)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    var t = date.toLocaleDateString("id-ID", options)
    return t;
  };

  const handleIdChange = (event) => {
    event.preventDefault();
    var l = event.target.elements.idkunjungan.value.length;
    if(l>0){
      setIdKunjungannya(event.target.elements.idkunjungan.value);
    }else{setIdKunjungannya(null);}
  };

  const sudahKunjunganOpt = [
    { value: true, label: 'Sudah' },
    { value: false, label: 'Belum' },
  ];

  const setKunjungan = () => {
    if (currentKunjungan==null) {setCurrentKunjungan(value.data())} else {return false;}
  };

  useEffect(() => {
    if(!loading){setKunjungan();}else{return false;}
  });

  const handleReset = () => {
    SetInputIdKunjunganFocus();setIdKunjungannya(null);setPrinted(false);
    setTimeout(function() {
      setCurrentKunjungan(null);
   }, 300);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentKunjungan({ ...currentKunjungan, [name]: value });
    setImageHashToRefresh(new Date().getTime());
  };

  function printKunjungan () {
      const data = {sudahkunjungan: true};
      KunjunganDataService.update(idkunjungannya, data)
        .then(() => {
          handlePrint();
        })
        .catch((e) => {
          console.log(e);
          showMessage("Kode Pendaftaran Tidak Valid !");
        });
  };

  const updateData = () => {
    Swal.fire({
      title: 'Ubah data?',
      text: "data akan diperbarui sesuai informasi saat ini",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          pengikut1: currentKunjungan.pengikut1,
          pengikut2: currentKunjungan.pengikut2,
          pengikut3: currentKunjungan.pengikut3,
          sudahkunjungan: currentKunjungan.sudahkunjungan,
        };
        KunjunganDataService.update(idkunjungannya, data)
          .then(() => {
            showMessage("Data kunjungan berhasil diubah");
          })
          .catch((e) => {
            console.log(e);
          });
      }
    })
  };

  const deleteData = () => {
    Swal.fire({
      title: 'Yakin ingin menghapus?',
      text: "data yang terhapus tidak dapat dikembalikan!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus'
    }).then((result) => {
      if (result.isConfirmed) {
        KunjunganDataService.remove(idkunjungannya)
        .then(() => {
          handleReset();
        })
        .catch((e) => {
          console.log(e)
          showMessage(e)
      });
      }
    })
  };

  return(
      <div>
        <Navigation />
        <div className="list row mt-20 mx-10">
        <div className="col-md-10">
        <h1 className="text-3xl mb-2 text-center font-bold">Proses Pendaftaran Kunjungan</h1>
        <div className="form-group">
          <form onSubmit={handleIdChange}>
          <label htmlFor="idkunjungan">ID Kunjungan</label>
            <input
              autoFocus
              required
              autoComplete="off"
              type="text"
              placeholder="Masukkan manual atau gunakan scanner"
              className="form-control"
              id="idkunjungan"
              name="idkunjungan"
              onFocus = {(e) => e.target.value = ""}
              ref= {inputIdKunjunganRef}
            /><br/>
            <input type="submit" className="btn btn-primary" onClick={handleReset}/><input type="reset" className="btn btn-warning" onClick={handleReset}/>
            </form>
        </div><hr/><br/>
        <div>
        <p>
        <strong>Detail Pendaftaran Kunjungan :</strong><br/>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}{loading && <span>Mengambil data...</span>}
        </p>
          <form>
          {idkunjungannya!==null&&currentKunjungan!=null&&
          <div className="form-group">
            <p>Tanggal Mendaftar : {convertDate(currentKunjungan.daftarjam.seconds)}</p>
          </div>}

          {idkunjungannya!==null&&currentKunjungan!=null&&
          <div className="form-group">
              <label htmlFor="sudahkunjungan">Sudah Kunjungan</label>
              <select className="form-control" id="sudahkunjungan" name="sudahkunjungan"
                value={currentKunjungan.sudahkunjungan} onChange={handleInputChange}>
                {sudahKunjunganOpt.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            }

          {idkunjungannya!==null&&currentKunjungan!=null&&
            <div className="form-group">
              <label htmlFor="pengikut1">Pengikut 1</label>
              <input
                type="text"
                className="form-control"
                id="pengikut1"
                name="pengikut1"
                value={currentKunjungan.pengikut1}
                onChange={handleInputChange}
              />
            </div>}

            {idkunjungannya!==null&&currentKunjungan!=null&&
            <div className="form-group">
              <label htmlFor="pengikut2">Pengikut 2</label>
              <input
                type="text"
                className="form-control"
                id="pengikut2"
                name="pengikut2"
                value={currentKunjungan.pengikut2}
                onChange={handleInputChange}
              />
            </div>}

            {idkunjungannya!==null&&currentKunjungan!=null&&
            <div className="form-group">
              <label htmlFor="pengikut3">Pengikut 3</label>
              <input
                type="text"
                className="form-control"
                id="pengikut3"
                name="pengikut3"
                value={currentKunjungan.pengikut3}
                onChange={handleInputChange}
              />
            </div>}

          {idkunjungannya!==null&&currentKunjungan!=null&&
          <div className="form-group">
              <label htmlFor="noktp">Nomor KTP</label>
              <input
                type="text"
                className="form-control"
                id="noktp"
                name="noktp"
                value={currentKunjungan.noktp}
                disabled={true}
              />
            </div>}

            {idkunjungannya!==null&&currentKunjungan!=null&&
            <div className="form-group">
              <label htmlFor="nama">Nama Pengunjung</label>
              <input
                type="text"
                className="form-control"
                id="nama"
                name="nama"
                value={currentKunjungan.namapengunjung}
                disabled={true}
              />
            </div>}

            {idkunjungannya!==null&&currentKunjungan!=null&&
            <div className="form-group">
              <label htmlFor="alamat">Alamat</label>
              <textarea
                rows="4"
                className="form-control"
                id="alamat"
                name="alamat"
                value={currentKunjungan.alamatpengunjung}
                disabled={true}
              />
            </div>}

            {idkunjungannya!==null&&currentKunjungan!=null&&
            <div className="form-group">
              <label htmlFor="fotodiri">Foto Pengunjung</label>
              <div className="hovereffect">
                <Image id="fotodiri" name="fotodiri" src={`${currentKunjungan.fotopengunjung}?t=${imageHashToRefresh}`} rounded />
                <div className="overlay">
				          <p><a href={currentKunjungan.fotopengunjung} target="_blank" rel="noreferrer">Lihat di Tab Baru</a></p> 
                </div>
              </div>
            </div>}

            {idkunjungannya!==null&&currentKunjungan!=null&&
            <div className="form-group">
              <label htmlFor="fotoktp">Foto WBP Pengunjung</label>
              <div className="hovereffect">
                <Image id="fotoktp" name="fotoktp" src={`${currentKunjungan.fotowbp}?t=${imageHashToRefresh}`} rounded />
                <div className="overlay">
				          <p><a href={currentKunjungan.fotowbp} target="_blank" rel="noreferrer">Lihat di Tab Baru</a></p> 
                </div>
              </div>
            </div>}

            {idkunjungannya!==null&&currentKunjungan!=null&&
            <div className="form-group">
              <label htmlFor="kamar">Blok /Kamar</label>
              <input
                type="text"
                className="form-control"
                id="kamar"
                name="kamar"
                value={currentKunjungan.kamar}
                disabled={true}
              />
            </div>}

        </form>

        <hr/>
        {idkunjungannya!==null&&currentKunjungan!=null&&!printed&& (() => {setTimeout(function() {
        printKunjungan();setPrinted(true);}, 300);})()}
        {idkunjungannya!==null&&currentKunjungan!=null&&
        <button className="btn btn-secondary mr-2" onClick={printKunjungan} >
          Cetak
        </button>}

        {idkunjungannya!==null&&currentKunjungan!=null&&
        <button className="btn btn-danger mr-2" onClick={deleteData}>
          Hapus Data
        </button>}

        {idkunjungannya!==null&&currentKunjungan!=null&&
        <button className="btn btn-success mr-2" onClick={updateData}>
          Simpan Perubahan
        </button>}

        <div style={{display:'none', height:'0px'}}>
        {idkunjungannya!==null&&currentKunjungan!=null&&
          <div ref={componentRef}>
            <p style={{textAlign: 'center'}}>
              <img style={{display: 'block', width: '20%', height: 'auto', marginLeft:'auto', marginRight:'auto'}} src={process.env.PUBLIC_URL + '/logo_kemenkumham.png'} alt="logo kemenkumham" />
              <span style={{display: 'block', fontSize:'150%'}}><strong>LEMBAGA PEMASYARAKATAN KELAS I SEMARANG</strong></span><span style={{display: 'block',fontSize:'140%'}}><strong>JL. RAYA SEMARANG-BOJA KM.4, SEMARANG 50188 Telp (024) 76433499</strong></span>
            </p>
            <p style={{textAlign: 'center'}}><span style={{textDecoration: 'underline', fontSize:'250%'}}>KUNJUNGAN - {convertDate(Math.floor(new Date().getTime()/1000))}</span></p>
                  <table style={{fontSize:'300%'}}>
                      <tbody>
                          <tr>
                            <td style={{width: '19%'}}>Nama Pengunjung</td>
                            <td style={{width: '1%'}}>:&nbsp;</td>
                            <td style={{width: '80%'}}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{width:'100%',textAlign:'left', fontSize:'130%'}}>{currentKunjungan.namapengunjung}</td><td/><td/>
                          </tr>
                          <tr>
                            <td style={{width: '19%'}}>Nomor KTP</td>
                            <td style={{width: '1%'}}>:&nbsp;</td>
                            <td style={{width: '80%'}}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{width:'100%',textAlign:'left', fontSize:'130%'}}>{currentKunjungan.noktp}</td><td/>
                          </tr>
                          <tr>
                            <td style={{width: '19%'}}>Daftar Pengikut</td>
                            <td style={{width: '1%'}}>:&nbsp;</td>
                            <td style={{width: '80%'}}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td><p style={{marginLeft: '1%'}}>{currentKunjungan.pengikut1}</p></td>
                          </tr>
                          <tr>
                            <td><p style={{marginLeft: '1%'}}>{currentKunjungan.pengikut2}</p></td>
                          </tr>
                          <tr>
                            <td><p style={{marginLeft: '1%'}}>{currentKunjungan.pengikut3}</p></td>
                          </tr><br/>
                        </tbody>
                        </table><br/>
                    <table style={{width: '100%', fontSize:'300%'}}>
                      <tbody>
                      <table style={{width: '100%', borderWidth:"2px", borderColor:"#000000", borderStyle:'solid', fontSize:'100%'}}>
                      <tbody>
                      <tr>
                        <td style={{width:'100%',textAlign:'center'}}><strong>WBP Yang Dikunjungi</strong></td>
                      </tr>
                      </tbody>
                    </table>
                <tr>
                  <td style={{width:'100%',textAlign:'left', fontSize:'130%'}}>{currentKunjungan.namawbp} bin {currentKunjungan.namabinwbp}</td><td/><td/>
                </tr>
                <tr>
                  <td style={{width: '19%'}}>Perkara</td>
                  <td style={{width: '1%'}}>:&nbsp;</td>
                  <td style={{width: '80%'}}>&nbsp;</td>
                </tr>
                <tr>
                  <td style={{width:'100%',textAlign:'left', fontSize:'130%'}}>{currentKunjungan.kasus}</td><td/><td/>
                </tr>
                <tr>
                  <td style={{width: '19%'}}>Blok / Kamar Hunian</td>
                  <td style={{width: '1%'}}>:&nbsp;</td>
                  <td style={{width: '80%'}}>&nbsp;</td>
                </tr>
                <tr>
                  <td style={{width:'100%',textAlign:'left', fontSize:'150%'}}>{currentKunjungan.kamar}</td><td/><td/>
                </tr>
              </tbody>
            </table><br/>
          </div>}
          </div>
        </div>
    </div>
    </div>
    </div>
  );
}


export default ProsesKunjungan;