import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import ControlDataService from "../../Services/ControlService";
import Navigation from "../Navigation";
import DatePicker from 'react-date-picker';
import { Table, Row, Col } from 'react-bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function ControlList() {
  const [dateValue, setDateValue] = useState(new Date());
  const [jenisKontrol, setJenisKontrol] = useState("kontrolpengamanan");

  const getLessDate = (setgdate) => {
    var lessDate = new Date(`${setgdate.getMonth()+1}/${setgdate.getDate()}/${setgdate.getFullYear()} 23:59:01`);
    return lessDate;
  }

  const getGreaterDate = (setldate) => {
    var greaterDate = new Date(`${setldate.getMonth()+1}/${setldate.getDate()}/${setldate.getFullYear()} 00:00:01`);
    return greaterDate;
  }

  const jeniskontroloptions = [
    { value: 'kontrolpengamanan', label: 'Pengamanan' },
    { value: 'kontrolwasrik', label: 'Wasrik' },
  ];

  const [gdateValue, setGdateValue] = useState(getGreaterDate(dateValue));
  const [ldateValue, setLdateValue] = useState(getLessDate(dateValue));
  const [controls, loading, error] = useCollection(ControlDataService.getControl(jenisKontrol,gdateValue,ldateValue));

  const onDateChange = (date) => {
    setDateValue(date);
    setGdateValue(getGreaterDate(date));
    setLdateValue(getLessDate(date));
  }

  const onJenisChange = (event) => {
    setJenisKontrol(event.target.value);
  }

  const convertTime = (secs) => {
    const date = new Date(secs*1000)
    const options = { hour:'numeric', minute:'numeric' }
    var t = date.toLocaleTimeString("id-ID", options)
    return t;
  }

  const convertDate = (secs) => {
    const date = new Date(secs)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    var t = date.toLocaleDateString("id-ID", options)
    return t;
  }

  return(
      <div>
        <Navigation />  
        <div className="list row mt-20 mx-10">
        <div className="col-md-10">
        <h1 className="text-3xl mb-2 text-center font-bold">Data E - Control</h1>
        <div className="form-group">
        <Row>
            <Col>
            <label htmlFor="tanggal">Pilih Tanggal</label><br/>
            <DatePicker
              id="tanggal"
              clearIcon={null}
              onChange={onDateChange}
              value={dateValue}
            />
            </Col>
            <Col>
            <label htmlFor="jeniskontrol">Pilih Jenis Kontrol</label>
              <select className="form-control" id="jeniskontrol" name="jeniskontrol"
                value={jenisKontrol} onChange={onJenisChange}>
                {jeniskontroloptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </Col>
            <Col>
            <label htmlFor="table-xls-button">Export ke Excel</label>
            <ReactHTMLTableToExcel
                    id="table-xls-button"
                    className="form-control btn btn-primary"
                    table="table-to-xls"
                    filename={"laporan-"+jenisKontrol+"-" +convertDate(dateValue)}
                    sheet="laporan-control"
                    buttonText="Download"/>
            </Col>
          </Row>
        </div><hr/><br/>
          {error && <strong>Error: {error}</strong>}
          {loading && <span>Memuat data...</span>}
          <ul className="list-group">
                <Table striped bordered hover id="table-to-xls">
                { !loading &&
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Jam Kontrol</th>
                    <th>Titik Kontrol</th>
                    <th>Petugas</th>
                    <th>Regu</th>
                    <th>Foto</th>
                  </tr>
                </thead>}
                { !loading && controls && controls.docs.map((control, index) => (
                <tbody>
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{convertTime(control.data().jamkontrol.seconds)}</td>
                    <td>
                      {control.data().poskontrol}
                    </td>
                    <td>
                      {control.data().petugas}
                    </td>
                    <td>
                      {control.data().regu}
                    </td>
                    <td>{(() => {
                        const fotonya = control.data().foto;
                        if (fotonya==="") {
                          return (
                            <font> - </font>
                          )
                        } else {
                          return (
                            <a href={fotonya} target="_blank" rel="noreferrer"><button>LIHAT</button></a>
                          )
                        }
                      })()}
                    </td>
                  </tr>
                </tbody>))}
              </Table>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ControlList;