import React, { useState } from "react";
import PetugasDataService from "../../Services/PetugasService";
import Swal from 'sweetalert2'
import { Image } from 'react-bootstrap';

const PetugasDetail = (props) => {
  const initialPetugasState = {
    key: null,
    nama: "",
    password: "",
    pin: "",
    regu: "",
  };
  const [currentPetugas, setCurrentPetugas] = useState(initialPetugasState);

  const { petugas } = props;
  if (currentPetugas.id !== petugas.id) {
    setCurrentPetugas(petugas);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentPetugas({ ...currentPetugas, [name]: value });
  };

  function showMessage (m) {
    Swal.fire({
      text: m,
      icon: 'info',
    })
  };

  const deletePetugas = () => {
    Swal.fire({
      title: 'Yakin ingin menghapus?',
      text: "data yang terhapus tidak dapat dikembalikan!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus'
    }).then((result) => {
      if (result.isConfirmed) {
          PetugasDataService.remove(currentPetugas.id)
          .then(() => {
            props.refreshList()
          })
          .catch((e) => {
            console.log(e);
          });
      }
    })
  };

  const updatePetugas = () => {
    Swal.fire({
      title: 'Ubah data?',
      text: "data akan diperbarui sesuai informasi saat ini",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          nama: currentPetugas.nama,
          pin: currentPetugas.pin,
        };
        PetugasDataService.update(currentPetugas.id, data)
          .then(() => {
            showMessage("Data pengguna berhasil diubah");
          })
          .catch((e) => {
            console.log(e);
          });
      }
    })
  };

  return (
    <div className='mx-10'>
        {currentPetugas ? (
        <div className="edit-form">
          <h1 className="text-3xl mb-2 text-center font-bold">Detail Data Petugas</h1>
          <form>
          <div className="form-group">
              <label htmlFor="nip">NIP</label>
              <input
                type="text"
                className="form-control"
                id="nip"
                name="nip"
                value={currentPetugas.id}
                disabled={true}
              />
            </div>

          <div className="form-group">
              <label htmlFor="regu">Regu</label>
              <input
                type="text"
                className="form-control"
                id="regu"
                name="regu"
                value={currentPetugas.regu}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="nama">Nama</label>
              <input
                type="text"
                className="form-control"
                id="nama"
                name="nama"
                value={currentPetugas.nama}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="fotoprofil">Foto Profil</label>
              <div className="hovereffect">
                <Image id="fotoprofil" name="fotoprofil" src={`${currentPetugas.foto}`} rounded alt='Belum upload foto profil' />
                <div className="overlay">
				          <p><a href={currentPetugas.foto} target="_blank" rel="noreferrer">Lihat di Tab Baru</a></p> 
                </div>
              </div>
            </div>
            <br/>

            <div className="form-group">
              <label htmlFor="pin">PIN</label>
              <input
                type="password"
                className="form-control"
                id="pin"
                name="pin"
                maxLength="6"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                value={currentPetugas.pin}
                onChange={handleInputChange}
              />
            </div>

        </form>

          <button className="btn btn-danger mr-2" onClick={deletePetugas}>
            Hapus
          </button>
          <button className="btn btn-success mr-2" onClick={updatePetugas}>
            Simpan Perubahan
          </button>
        </div>
      ) : (
        <div>
          <br/>
          <p><strong>Pilih salah satu data untuk melihat detailnya...</strong></p>
        </div>
      )}
    </div>
  );
};

export default PetugasDetail;