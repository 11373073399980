import React, { useState } from "react";


const KirangDetail = (props) => {
  const initialKirangState = {
    key: null,
    namapengirim: "",
    fotowbp: "",
    alamatpengirim: "",
    kasus: "",
    rincianbarang: "",
    hubungan: "",
    namawbp: "",
    namabinwbp: "",
    tglterkirim: {
      nanoseconds: "",
      seconds: "",
    },
    tgldaftar: {
      nanoseconds: "",
      seconds: "",
    },
    fotopengirim: "",
    status: "",
    kamar: "",
    noktp: "",
    noidwbp: "",
  };
  const [currentKirang, setCurrentKirang] = useState(initialKirangState);
  const [progress, setProgress] = useState(0);

  const { kirang } = props;
  if (currentKirang.id !== kirang.id) {
    setCurrentKirang(kirang);
  }

  const convertDate = (secs) => {
    const date = new Date(secs*1000)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    var t = date.toLocaleDateString("id-ID", options)
    return t;
  }

  return (
    <div className='mx-10'>
        {currentKirang ? (
        <div className="edit-form">
          <h1 className="text-3xl mb-2 text-center font-bold">Detail Kiriman Barang</h1>
          <form>
          <div className="form-group">
            <br/><strong>Didaftarkan : {convertDate(currentKirang.tgldaftar.seconds)}</strong>
            <br/><strong>Terkirim : {convertDate(currentKirang.tglterkirim.seconds)}</strong>
          </div>

          <div className="form-group">
              <label htmlFor="noktp">ID Kiriman</label>
              <input
                type="text"
                className="form-control"
                id="noktp"
                name="noktp"
                value={currentKirang.id}
                disabled={true}
              />
            </div>

            <div className="form-group">
            <label htmlFor="namapengirim">Pengirim</label>
              <input
                type="text"
                className="form-control"
                id="namapengirim"
                name="namapengirim"
                value={currentKirang.namapengirim+' | KTP : '+currentKirang.noktp}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="alamatpengirim">Alamat Pengirim</label>
              <textarea
                rows="2"
                className="form-control"
                id="alamatpengirim"
                name="alamatpengirim"
                value={currentKirang.alamatpengirim}
                disabled={true}
              />
              </div>

              <div className="form-group">
              <label htmlFor="hubungan">Hubungan dengan WBP</label>
              <input
                type="text"
                className="form-control"
                id="hubungan"
                name="hubungan"
                value={currentKirang.hubungan}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="namawbp">WBP Yang Dikirimi</label>
              <input
                type="text"
                className="form-control"
                id="namawbp"
                name="namawbp"
                value={currentKirang.namawbp+' Bin '+currentKirang.namabinwbp}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="noidwbp">Nomor Berkas WBP</label>
              <input
                type="text"
                className="form-control"
                id="noidwbp"
                name="noidwbp"
                value={currentKirang.noidwbp}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="kamar">Kamar</label>
              <input
                type="text"
                maxLength="3"
                className="form-control"
                id="kamar"
                name="kamar"
                value={currentKirang.kamar}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="rincianbarang">Ringkasan Isi Barang</label>
              <textarea
                rows="2"
                type="text"
                className="form-control"
                id="rincianbarang"
                name="rincianbarang"
                value={currentKirang.rincianbarang}
                disabled={true}
              />
            </div>

        </form>
        </div>
      ) : (
        <div>
          <br/>
          <p><strong>Pilih salah satu data untuk melihat detailnya...</strong></p>
        </div>
      )}
    </div>
  );
};

export default KirangDetail;