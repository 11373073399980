import React, { useState } from "react";
import { auth, generateAdminDocument } from "../../firebase";
import Navigation from "../Navigation";

const AddAdmin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [error, setError] = useState(null);

  const createAdminWithEmailAndPasswordHandler = async (event, displayName, email, password) => {
    event.preventDefault();
    setError(null)
    if (displayName === "" || email === "" || password === "") {
      setError("Masih ada kolom yang kosong!");
    }else{
      try{
        const {user} = await auth.createUserWithEmailAndPassword(email, password);
        generateAdminDocument(user, {displayName});
      }catch(error){
        setError(error.message);
      }
    }
    setEmail("");
    setPassword("");
    setDisplayName("");
  };

  const onChangeHandler = event => {
    const { name, value } = event.currentTarget;

    if (name === "adminEmail") {
      setEmail(value);
    } else if (name === "adminPassword") {
      setPassword(value);
    } else if (name === "adminUsername") {
      setDisplayName(value);
    }
  };

  return (
    <div>
      <Navigation/>
      <div className="mt-20">
        <h1 className="text-3xl mb-2 text-center font-bold">Daftarkan Admin</h1>
        <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
          {error !== null && (
            <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
              {error}
            </div>
          )}
          <form className="">
            <label htmlFor="displayName" className="block">
              Username :
            </label>
            <input
              type="text"
              className="my-1 p-1 w-full "
              name="adminUsername"
              value={displayName}
              placeholder="Masukkan username"
              id="displayName"
              onChange={event => onChangeHandler(event)}
              required/>
            <label htmlFor="userEmail" className="block">
              Email :
            </label>
            <input
              type="email"
              className="my-1 p-1 w-full"
              name="adminEmail"
              value={email}
              placeholder="Masukkan e-mail aktif"
              id="adminEmail"
              onChange={event => onChangeHandler(event)}
              required/>
            <label htmlFor="adminPassword" className="block">
              Password :
            </label>
            <input
              type="password"
              className="mt-1 mb-3 p-1 w-full"
              name="adminPassword"
              value={password}
              placeholder="Masukkan password"
              id="adminPassword"
              onChange={event => onChangeHandler(event)}
              required/>
            <button
              className="bg-green-400 hover:bg-green-500 w-full py-2 text-white"
              onClick={event => {
                createAdminWithEmailAndPasswordHandler(event, displayName, email, password);
              }}
            >
              Daftar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
