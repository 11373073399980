import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useParams } from 'react-router-dom'
import KeluargaDataService from "../../Services/KeluargaService";
import KeluargaDetail from "./KeluargaDetail"
import Navigation from "../Navigation"

function KeluargaList() {
  var { iduser } = useParams()

  const [currentKeluarga, setCurrentKeluarga] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchvalue, setSearchValue] = useState(null);

  const [keluargas, loading, error] = useCollection(KeluargaDataService.getVerifiedKeluarga(iduser,searchvalue));

  const refreshList = () => {
    setCurrentKeluarga(null);
    setCurrentIndex(-1);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const setActiveKeluarga = (keluarga, index) => {
    const { nama, nik, hubungan} = keluarga.data();

    setCurrentKeluarga({
      id: keluarga.id,
      nama,
      nik,
      hubungan,
    });

    setCurrentIndex(index);
  };

  return(
      <div>
        <Navigation />  
        <div className="list row mt-20 mx-10">
        <div className="col-md-6">
        <h1 className="text-3xl mb-2 text-center font-bold">Daftar Keluarga Lainnya (Sebagai Pengikut)</h1>
        <div className="form-group">
          <label htmlFor="telp">Cari Berdasarkan Nama</label>
          <input
            type="text"
            placeholder="masukkan nama"
            className="form-control"
            id="cari"
            name="cari"
            onChange={handleSearchChange}
          />
        </div><hr/><br/>
          {error && <strong>Error: {error}</strong>}
          {loading && <span>Memuat data...</span>}
          <ul className="list-group">
            { !loading &&
              keluargas &&
              keluargas.docs.map((keluarga, index) => (
                <li
                  className={"list-group-item " + (index === currentIndex ? "active" : "")}
                  onClick={() => setActiveKeluarga(keluarga, index)}
                  key={keluarga.id}
                >
                  {index+1}.{' '}{ keluarga.data().nama }
                </li>
              ))}
          </ul>
        </div>
        <div className="col-md-6">
          {currentKeluarga ? (
            <KeluargaDetail keluarga={currentKeluarga} refreshList={refreshList} />
          ) : (
            <div>
              <br/><br/><br/>
              <p><strong align='center'>Pilih salah satu data untuk melihat detailnya...</strong></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default KeluargaList;