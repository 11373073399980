import React, { useState } from "react";
import PetugasDataService from "../../Services/PetugasService";
import Navigation from "../Navigation";
import Swal from 'sweetalert2'

const AddPetugas = () => {
  const [pin, setPin] = useState("");
  const [nama, setNama] = useState("");
  const [nip, setNip] = useState("");
  const [regu, setRegu] = useState("");
  const [error, setError] = useState(null);

  const reguOptions = [
    { value: 'REGU 1', label: 'REGU 1' },
    { value: 'REGU 2', label: 'REGU 2' },
    { value: 'REGU 3', label: 'REGU 3' },
    { value: 'REGU 4', label: 'REGU 4' },
    { value: 'PERWIRA KONTROL', label: 'PERWIRA KONTROL' },
    { value: 'PIKET STAF', label: 'PIKET STAF' }
  ];

  function showMessage (m) {
    Swal.fire({
      text: m,
      icon: 'info',
    }).then(()=>{
      window.location.replace("/petugass");
    })
  };

  const createPetugasHandler = async (event, nama, nip, pin, regu) => {
    event.preventDefault();
    setError(null)
    if (nama === "" || pin === ""|| nip === "") {
      setError("Masih ada kolom yang kosong!");
    }else{
      Swal.fire({
        title: 'Simpan Data?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Batal',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya'
      }).then((result) => {
        if (result.isConfirmed) {
          if (regu=="") {
            const data1 = {
              foto: "",
              nama: nama,
              pin: pin,
              regu: "REGU 1",
            };
            PetugasDataService.create(nip, data1)
            .then(() => {
              showMessage("Data petugas berhasil didaftarkan");
            })
            .catch((e) => {
              console.log(e);
            });
          } else {
            const data2 = {
              foto: "",
              nama: nama,
              pin: pin,
              regu: regu,
            };
            PetugasDataService.create(nip, data2)
            .then(() => {
              showMessage("Data petugas berhasil didaftarkan");
            })
            .catch((e) => {
              console.log(e);
            });
          }
        }
      });
    }
    setNama("");setNip("");setPin("");setRegu("");
  };

  const onChangeHandler = event => {
    const { name, value } = event.currentTarget;
    if (name === "petugasNip") {
      setNip(value);
    } else if (name === "petugasPin") {
      setPin(value);
    } else if (name === "petugasNama") {
      setNama(value);
    } else if (name === "petugasRegu") {
      setRegu(value);
    }
  };

  return (
    <div>
      <Navigation/>
      <div className="mt-20">
        <h1 className="text-3xl mb-2 text-center font-bold">Daftarkan Petugas</h1>
        <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
          {error !== null && (
            <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
              {error}
            </div>
          )}
          <form className="">
            <label htmlFor="petugasNip" className="block">
              NIP :
            </label>
            <input
              type="text"
              className="my-1 p-1 w-full "
              name="petugasNip"
              value={nip}
              placeholder="Masukkan NIP petugas"
              id="petugasNip"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={event => onChangeHandler(event)}
              required/>
            <label htmlFor="petugasNama" className="block">
              Nama :
            </label>
            <input
              type="text"
              className="my-1 p-1 w-full"
              name="petugasNama"
              value={nama}
              placeholder="Masukkan nama petugas"
              id="petugasNama"
              onChange={event => onChangeHandler(event)}
              required/>
            <label htmlFor="petugasPin" className="block">
              PIN :
            </label>
            <input
              type="password"
              className="mt-1 mb-3 p-1 w-full"
              name="petugasPin"
              value={pin}
              placeholder="Masukkan PIN"
              id="petugasPin"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={event => onChangeHandler(event)}
              maxLength="6"
              required/>
              <label htmlFor="petugasRegu" className="block">
              Regu :
              </label>
              <select className="form-control" id="petugasRegu" name="petugasRegu" value={regu} onChange={event => onChangeHandler(event)}>
                {reguOptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select><br/>
            <button
              className="bg-green-400 hover:bg-green-500 w-full py-2 text-white"
              onClick={event => {
                createPetugasHandler(event, nama, nip, pin, regu);
              }}
            >
              Daftar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPetugas;
