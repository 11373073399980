import {firestore, storage} from "../firebase";

const db = firestore.collection("/berita");

const getBerita = () => {
  return db.orderBy("tanggal","desc");
};

const create = (data) => {
  return db.add(data);
};

const update = (id, value) => {
  return db.doc(id).update(value);
};

const remove = (id) => {
  return db.doc(id).delete();
};

const delfoto = (url) => {
  var fileRef = storage.refFromURL(url);
  return fileRef.delete();
};

const BeritaService = {
  getBerita,
  create,
  update,
  remove,
  delfoto
};

export default BeritaService;