import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import BeritaDataService from "../../Services/BeritaService";
import Navigation from "../Navigation";
import { Table } from 'react-bootstrap';
import Swal from 'sweetalert2'

function BeritaList() {
  const [beritas, loading, error] = useCollection(BeritaDataService.getBerita());
  const [statusAksi, setStatusAksi] = useState(false);

  function showMessage (m) {
    Swal.fire({
      text: m,
      icon: 'info',
    }).then(()=>{
      return;
    })
  };

  const hapusBerita = (idberitanya, linkfotonya) => {
    Swal.fire({
      title: 'Yakin ingin menghapus berita?',
      text: "data yang terhapus tidak dapat dikembalikan!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus'
    }).then((result) => {
      if (result.isConfirmed) {
          BeritaDataService.delfoto(linkfotonya)
          .then(() => {
            BeritaDataService.remove(idberitanya)
          })
          .catch((e) => {
            console.log(e);
          });
      }
    })
  };

  return(
      <div>
        <Navigation />  
        <div className="list row mt-20 mx-10">
        <div className="col-md-10">
        <h1 className="text-3xl mb-2 text-center font-bold">Kelola Berita Lapas</h1>
          {error && <strong>Error: {error}</strong>}
          {loading && <span>Memuat data...</span>}
          <ul className="list-group">
                <Table striped bordered hover>
                { !loading &&
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Foto</th>
                    <th>Judul</th>
                    <th>Isi Berita</th>
                    <th>Izin Aksi <input type="checkbox" checked={statusAksi} onChange={e => setStatusAksi(e.target.checked)}/></th>
                  </tr>
                </thead>}
                { !loading && beritas && beritas.docs.map((berita, index) => (
                <tbody>
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td><img src={berita.data().foto} /></td>
                    <td>{berita.data().judul}</td>
                    <td>{berita.data().isi}</td>
                    <td><button className="btn btn-danger" onClick={ (()=>{
                      if (statusAksi) {
                        hapusBerita(berita.id, berita.data().foto);
                      } else {
                        showMessage('Aktifkan terlebih dahulu izin untuk melakukan aksi.');
                      }
                    }) }>Hapus</button></td>
                  </tr>
                </tbody>))}
              </Table>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default BeritaList;