import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCollection } from "react-firebase-hooks/firestore";
import PelaporanDataService from "../../Services/PelaporanService";
import Navigation from "../Navigation";
import DatePicker from 'react-date-picker';
import { Table, Row, Col } from 'react-bootstrap';

function PelaporanList() {
  const [dateValue, setDateValue] = useState(new Date());

  const getLessDate = (setgdate) => {
    var lessDate = new Date(`${setgdate.getMonth()+1}/${setgdate.getDate()}/${setgdate.getFullYear()} 23:59:01`);
    return lessDate;
  }

  const getGreaterDate = (setldate) => {
    var greaterDate = new Date(`${setldate.getMonth()+1}/${setldate.getDate()}/${setldate.getFullYear()} 00:00:01`);
    return greaterDate;
  }

  const [gdateValue, setGdateValue] = useState(getGreaterDate(dateValue));
  const [ldateValue, setLdateValue] = useState(getLessDate(dateValue));
  const [laporans, loading, error] = useCollection(PelaporanDataService.getLaporan(gdateValue,ldateValue));

  const onDateChange = (date) => {
    setDateValue(date);
    setGdateValue(getGreaterDate(date));
    setLdateValue(getLessDate(date));
  }

  return(
      <div>
        <Navigation />  
        <div className="list row mt-20 mx-10">
        <div className="col-md-10">
        <h1 className="text-3xl mb-2 text-left font-bold">Pelaporan</h1>
        <div className="form-group">
          <Row>
            <Col>
              <label htmlFor="tanggal">Pilih Tanggal</label><br/>
              <DatePicker
                id="tanggal"
                clearIcon={null}
                onChange={onDateChange}
                value={dateValue}
              />
            </Col>
          </Row>
        </div><hr/><br/>
          {error && <strong>Error: {error}</strong>}
          {loading && <span>Memuat data...</span>}
          <ul className="list-group">
                <Table striped bordered hover>
                { !loading &&
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Pengirim</th>
                    <th>Isi Laporan</th>
                    <th>Aksi</th>
                  </tr>
                </thead>}
                { !loading && laporans && laporans.docs.map((laporan, index) => (
                <tbody>
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{laporan.data().pengirim}</td>
                    <td>{laporan.data().pesan}</td>
                    <td><Link className="btn btn-secondary" to={`/chatnya/${laporan.data().idpengirim}/${laporan.data().pengirim}`} >Tindak Lanjut / Tanggapi</Link></td>
                  </tr>
                </tbody>))}
              </Table>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PelaporanList;