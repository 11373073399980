import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import TrafficDataService from "../../Services/TrafficService";
import Navigation from "../Navigation";
import DatePicker from 'react-date-picker';
import { Table, Row, Col } from 'react-bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function TrafficList() {
  const [dateValue, setDateValue] = useState(new Date());

  const getLessDate = (setgdate) => {
    var lessDate = new Date(`${setgdate.getMonth()+1}/${setgdate.getDate()}/${setgdate.getFullYear()} 23:59:01`);
    return lessDate;
  }

  const getGreaterDate = (setldate) => {
    var greaterDate = new Date(`${setldate.getMonth()+1}/${setldate.getDate()}/${setldate.getFullYear()} 00:00:01`);
    return greaterDate;
  }

  const [gdateValue, setGdateValue] = useState(getGreaterDate(dateValue));
  const [ldateValue, setLdateValue] = useState(getLessDate(dateValue));
  const [traffics, loading, error] = useCollection(TrafficDataService.getTraffic(gdateValue,ldateValue));

  const onDateChange = (date) => {
    setDateValue(date);
    setGdateValue(getGreaterDate(date));
    setLdateValue(getLessDate(date));
  }

  const convertTime = (secs) => {
    const date = new Date(secs*1000)
    const options = { hour:'numeric', minute:'numeric' }
    var t = date.toLocaleTimeString("id-ID", options)
    return t;
  }

  const convertDate = (secs) => {
    const date = new Date(secs)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    var t = date.toLocaleDateString("id-ID", options)
    return t;
  }

  return(
      <div>
        <Navigation />
        <div className="list row mt-20 mx-10">
        <div className="col-md-10">
        <h1 className="text-3xl mb-2 text-center font-bold">Data E - Traffic</h1>
        <div className="form-group">
        <Row>
            <Col>
            <label htmlFor="tanggal">Pilih Tanggal</label><br/>
            <DatePicker
              id="tanggal"
              clearIcon={null}
              onChange={onDateChange}
              value={dateValue}
            />
            </Col>  
            <Col>
            <label htmlFor="table-xls-button">Export ke Excel</label>
            <ReactHTMLTableToExcel
                      id="table-xls-button"
                      className="form-control btn btn-primary"
                      table="table-to-xls"
                      filename={"laporan-lalulintas-kendaran-"+convertDate(dateValue)}
                      sheet="lalulintas-kendaraan"
                      buttonText="Download"/>
            </Col>
          </Row>
        </div><hr/><br/>
          {error && <strong>Error: {error}</strong>}
          {loading && <span>Memuat data...</span>}
          <ul className="list-group">
                <Table striped bordered hover id="table-to-xls">
                { !loading &&
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Jam Masuk</th>
                    <th>Jam Keluar</th>
                    <th>Plat Nomor</th>
                    <th>Keperluan</th>
                    <th>Foto</th>
                  </tr>
                </thead>}
                { !loading && traffics && traffics.docs.map((traffic, index) => (
                <tbody>
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{convertTime(traffic.data().masukjam.seconds)}</td>
                    <td>{(() => {
                      if (traffic.data().sudahkeluar) {
                        return (
                          <font> {convertTime(traffic.data().keluarjam.seconds)}</font>
                        )
                      } else {
                        return (
                          <font> - </font>
                        )
                      }
                      })()}  
                    </td>
                    <td>
                      {traffic.data().platnomor}
                    </td>
                    <td>
                      {traffic.data().keperluan}
                    </td>
                    <td>{(() => {
                        const fotonya = traffic.data().foto;
                        if (fotonya==="") {
                          return (
                            <font> - </font>
                          )
                        } else {
                          return (
                            <a href={fotonya} target="_blank" rel="noreferrer"><button>LIHAT</button></a>
                          )
                        }
                      })()}
                    </td>
                  </tr>
                </tbody>))}
              </Table>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TrafficList;