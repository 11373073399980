import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import KirangDataService from "../../Services/KirangService";
import KirangDetail from "./KirangDetail"
import Navigation from "../Navigation"
import DatePicker from 'react-date-picker';

function KirangList() {

  const [currentKirang, setCurrentKirang] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [dateValue, setDateValue] = useState(new Date());

  const getLessDate = (setgdate) => {
    var lessDate = new Date(`${setgdate.getMonth()+1}/${setgdate.getDate()}/${setgdate.getFullYear()} 23:59:01`);
    return lessDate;
  }

  const getGreaterDate = (setldate) => {
    var greaterDate = new Date(`${setldate.getMonth()+1}/${setldate.getDate()}/${setldate.getFullYear()} 00:00:01`);
    return greaterDate;
  }

  const [gdateValue, setGdateValue] = useState(getGreaterDate(dateValue));
  const [ldateValue, setLdateValue] = useState(getLessDate(dateValue));
  const [kirangs, loading, error] = useCollection(KirangDataService.getSentKirang(gdateValue,ldateValue));
  
  const refreshList = () => {
    setCurrentKirang(null);
    setCurrentIndex(-1);
  };

  const onDateChange = (date) => {
    refreshList();
    setDateValue(date);
    setGdateValue(getGreaterDate(date));
    setLdateValue(getLessDate(date));
  }

  const setActiveKirang = (kirang, index) => {
    const { namapengirim, fotowbp, alamatpengirim, kasus, rincianbarang, hubungan, namawbp, namabinwbp,
    tglterkirim, tgldaftar, fotopengirim, status, kamar, noktp,noidwbp, } = kirang.data();

    setCurrentKirang({
      id: kirang.id,
      namapengirim, fotowbp, alamatpengirim, kasus, rincianbarang, hubungan, namawbp, namabinwbp,
      tglterkirim, tgldaftar, fotopengirim, status, kamar, noktp,noidwbp,
    });

    setCurrentIndex(index);
  };

  return(
      <div>
        <Navigation />  
        <div className="list row mt-20 mx-10">
        <div className="col-md-6">
        <h1 className="text-3xl mb-2 text-center font-bold">Daftar Riwayat Kiriman Barang</h1>
        <div className="form-group">
          <label htmlFor="tanggal">Pilih Tanggal</label><br/>
          <DatePicker
            id="tanggal"
            clearIcon={null}
            onChange={onDateChange}
            value={dateValue}
          /></div><hr/><br/>
          {error && <strong>Error: {error}</strong>}
          {loading && <span>Memuat data...</span>}
          <ul className="list-group">
            { !loading &&
              kirangs &&
              kirangs.docs.map((kirang, index) => (
                <li
                  className={"list-group-item " + (index === currentIndex ? "active" : "")}
                  onClick={() => setActiveKirang(kirang, index)}
                  key={kirang.id}
                >
                  {index+1}.{' '}{ kirang.data().namapengirim }{' | '}{kirang.data().namawbp}{' bin '}{kirang.data().namabinwbp}
                </li>
              ))}
          </ul>
        </div>
        <div className="col-md-6">
          {currentKirang ? (
            <KirangDetail kirang={currentKirang} refreshList={refreshList} />
          ) : (
            <div>
              <br/><br/><br/>
              <p><strong align='center'>Pilih salah satu data untuk melihat detailnya...</strong></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default KirangList;