import React, { useState } from "react";
import BeritaService from "../../Services/BeritaService";
import { storage, firestore } from "../../firebase";
import Navigation from "../Navigation";
import Swal from 'sweetalert2'
import { Image } from 'react-bootstrap';

const AddBerita = () => {
  const [urlFoto, setUrlFoto] = useState("");
  const [judul, setJudul] = useState("");
  const [isi, setIsi] = useState("");
  const [error, setError] = useState(null);
  const [photoLoc, setPhotoLoc] = useState("");
  const [progress, setProgress] = useState(0);
  const [imageHashToRefresh, setImageHashToRefresh] = useState("");

  
  async function gantiFotoBerita () {
    const { value: file } = await Swal.fire({
      title: 'Pilih Foto Berita',
      text: 'Foto akan secara otomatis tersimpan setelah diupload',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Upload',
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Upload foto berita'
      }
    })
    
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        e.preventDefault();
        if(photoLoc==""){
          const datename = new Date().getTime();
          const uploadTask = storage.ref(`/fotoberita/${datename}_${file.name}`).put(file);
          uploadTask.on(
            "state_changed",
            snapshot => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgress(progress);
            },
            error => {
              console.log(error);
            },
            () => {
              Swal.fire({
                icon: 'success',
                title: 'Foto berita berhasil diganti',
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                storage
                .ref("fotoberita")
                .child(datename+"_"+file.name)
                .getDownloadURL()
                .then((url) => {
                  setUrlFoto(url);
                  setPhotoLoc(`/fotoberita/${datename}_${file.name}`);
                  setImageHashToRefresh(new Date().getTime());
                })
              });
            }
          );
        }else{
          const uploadTask = storage.ref(photoLoc).put(file);
          uploadTask.on(
            "state_changed",
            snapshot => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgress(progress);
            },
            error => {
              console.log(error);
            },
            () => {
              Swal.fire({
                icon: 'success',
                title: 'Foto berita berhasil diganti',
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                setImageHashToRefresh(new Date().getTime());
              });
            }
          );
        }
      }
      reader.readAsDataURL(file)
    }
  };
  
  function showMessage (m) {
    Swal.fire({
      text: m,
      icon: 'info',
    }).then(()=>{
      window.location.replace("/newss");
    })
  };

  const createBeritaHandler = async (event, judul, isi) => {
    event.preventDefault();
    setError(null);
    if (judul === "" || urlFoto === ""|| isi === "") {
      setError("Pastikan foto sudah diupload dan semua kolom terisi!");
    }else{
      Swal.fire({
        title: 'Tambahkan Berita?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Batal',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya'
      }).then((result) => {
        if (result.isConfirmed) {
            const data = {
              judul: judul,
              foto: urlFoto,
              isi: isi,
              tanggal: new Date()
            };
            BeritaService.create(data)
            .then(() => {
              const requestNotifOptions = {
                method: "POST",
                headers: { "content-type": "application/json", "authorization": "key=AAAA9qTy6zA:APA91bH8PJ5l5GfbXpr4XUErtv-Bbkc08ok3hotwKi1P39XCJWkCBtWAUqq5Q0gzaSguASf0LpODm77J5lz-K_MV6__DwDnQ_1Y0dgWFnS_-plT5ie8tU3rOaYcnNzbCNkMVYdGU0_dr"},
                body: JSON.stringify(
                  {
                    "to" : "/topics/58cSdAj4XStBeZBpzyCwu07vDE1ws1uOxAHNgink",
                    "collapse_key" : "type_a",
                    "notification" : {
                        "body" : judul,
                        "title": "Berita Lapas Terbaru",
                        "click_action":"BUKA_ACTIVITY_MAIN"
                    },
                    "data" : {
                        "body" : judul,
                        "title": "Berita Lapas Terbaru",
                        "messagetype" : "berita"
                    }
                   }
                )
              }
              fetch('https://fcm.googleapis.com/fcm/send', requestNotifOptions)
              .then(response => {
                response.json();
                showMessage("Berita berhasil ditambahkan");
              });
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    }
  };

  const onChangeHandler = event => {
    const { name, value } = event.currentTarget;
    if (name === "judulBerita") {
      setJudul(value);
    } else {
      setIsi(value);
    }
  };

  return (
    <div>
      <Navigation/>
      <div className="mt-20">
        <h1 className="text-3xl mb-2 text-center font-bold">Tambah Berita</h1>
        <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
          {error !== null && (
            <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
              {error}
            </div>
          )}
          <form className="">
          <label htmlFor="fotoBerita" className="block">
              Foto Berita :
            </label>
            <div name="fotoBerita" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Image style={{height: "300px", width: "auto", alignSelf: "center"}} src={`${urlFoto}?t=${imageHashToRefresh}`} onError={(e) => {
            e.target.src = process.env.PUBLIC_URL+'/no-image.png'}} rounded />
            </div>
            <label htmlFor="judulBerita" className="block">
              Judul Berita :
            </label>
            <input
              type="text"
              className="my-1 p-1 w-full "
              name="judulBerita"
              value={judul}
              placeholder="Masukkan judul berita"
              id="judulBerita"
              onChange={event => onChangeHandler(event)}
              required/>
            <label htmlFor="isiBerita" className="block">
              Isi Berita :
            </label>
            <textarea
              type="text"
              className="my-1 p-1 w-full"
              name="isiBerita"
              value={isi}
              rows="10"
              placeholder="Masukkan narasi berita"
              id="isiBerita"
              onChange={event => onChangeHandler(event)}
              autoFocus
              required/>

            <button
              className="bg-blue-400 hover:bg-blue-500 w-full py-2 text-white"
              onClick={() => {
                gantiFotoBerita()
              }}
            >
              Ganti Foto Berita
            </button>
            <hr/>
            <button
              className="bg-green-400 hover:bg-green-500 w-full py-2 text-white"
              onClick={event => {
                createBeritaHandler(event, judul, isi);
              }}
            >
              Tambahkan
            </button>
                       

          </form>
        </div>
      </div>
    </div>
  );
};

export default AddBerita;
