import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useParams } from 'react-router-dom'
import ChatDataService from "../../Services/ChatService";
import Navigation from "../Navigation"
import "bootstrap/dist/css/bootstrap.min.css";
import ChatBg from "../../Assets/images/chatbg.png"

function ChatDetail() {
  var { iduser, username } = useParams();
  const [pesan, setPesan] = useState("");

  const [chats, loading, error] = useCollection(ChatDataService.getAllMessages(iduser));

  const handlePesanChange = (event) => {
    setPesan(event.target.value);
  };

  const sendMessageHandler = async (event) => {
    event.preventDefault();
    if (pesan === "") {
      console.log("pesan tidak boleh kosong");
    }else{
        const datapesan = {
            isadmin:true,
            pesan:pesan,
            terbalas:true,
            userid:iduser,
            username:username,
            waktu:new Date(),
          };
          ChatDataService.sendMessage(iduser, datapesan)
          .then(() => {
            const requestNotifOptions = {
              method: "POST",
              headers: { "content-type": "application/json", "authorization": "key=AAAA9qTy6zA:APA91bH8PJ5l5GfbXpr4XUErtv-Bbkc08ok3hotwKi1P39XCJWkCBtWAUqq5Q0gzaSguASf0LpODm77J5lz-K_MV6__DwDnQ_1Y0dgWFnS_-plT5ie8tU3rOaYcnNzbCNkMVYdGU0_dr"},
              body: JSON.stringify(
                {
                  "to" : "/topics/cmKeEvRBWPBg5LqVHQcOLX0Ftp0YOQeszPKTwRg80Y"+iduser,
                  "collapse_key" : "type_a",
                  "notification" : {
                      "body" : pesan,
                      "title": "Jawaban dari Petugas",
                      "click_action":"BUKA_ACTIVITY_CHAT"
                  },
                  "data" : {
                      "body" : pesan,
                      "title": "Jawaban dari Petugas",
                      "messagetype" : "notifikasi"
                  }
                 }
              )
            }
            fetch('https://fcm.googleapis.com/fcm/send', requestNotifOptions)
            .then(response => response.json());
          })
          .catch((e) => {
            console.log(e);
          });
    }
  };

  return(
      <div>
        <Navigation />  
        <div className="list row mt-20 mx-10">
        <div className="col-md-10" style={{backgroundImage:`url(${ChatBg})`}}>
        <h1 className="text-3xl mb-2 text-center font-bold">Percakapan dengan {username}</h1><hr/><br/>
          {error && <strong>Error: {error}</strong>}
          {loading && <span>Memuat percakapan...</span>}
          <div className="list-group">
            { !loading &&
              chats &&
              chats.docs.map((message, index) => (
              <div>
                  {(() => {
                    if (message.data().isadmin) {
                      return (
                        <div
                          key={index}
                          className={"list-group-item active"}
                          style={{margin:'0px 0px 20px 0px', float:'right'}}
                          key={message.id}
                        >
                          { message.data().pesan }
                        </div>
                      )
                    } else {
                      return (
                        <div
                          key={index}
                          className={"list-group-item"}
                          style={{margin:'0px 0px 20px 0px', float:'left'}}
                          key={message.id}
                        >
                          { message.data().pesan }
                        </div>
                      )
                    }
                  })()}
                </div>
              ))}
          </div>
          <hr/>
          <br/>
          <div className="input-group">
              <textarea type="text" className="form-control" placeholder="Tulis pesan..." aria-label="Tulis pesan..." aria-describedby="basic-addon2" onChange={handlePesanChange}/>
              <div className="input-group-append mb-2">
                <button className="btn btn-outline-secondary" type="submit" onClick={sendMessageHandler}>KIRIM</button>
              </div>
          </div>
        </div>
      </div>
      <br/>
    </div>
  );
}

export default ChatDetail;