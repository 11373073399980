import React, {useContext, useState, useEffect} from "react";
import { AuthContext } from "../Services/Authentication";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Col, Row, Button, Container } from 'react-bootstrap';
import "../App.css";
import Navigation from "./Navigation";
import {firestore} from "../firebase";

function Dashboard() {
  const thisauthcontext = useContext(AuthContext);
  const [totalPAkun, setTotalPAkun] = useState(0);
  const [totalPHWbp, setTotalPHWbp] = useState(0);
  const [totalPetugas, setTotalPetugas] = useState(0);
  const [totalTraffic, setTotaltraffic] = useState(0);
  const [totalKontrol, setTotalKontrol] = useState(0);
  const [totalKontrolWasrik, setTotalKontrolWasrik] = useState(0);
  const [totalPelaporan, setTotalPelaporan] = useState(0);

  const getCountRequestingAccount = () => {
    firestore.collection("user").where("sudahaktif","==",false).get()
      .then(snapshot => {
        setTotalPAkun(snapshot.size.toString());
      }).catch(err => {
      console.log("Error getting documents", err);
    });
  };

  const getCountRequestingWbp = () => {
    firestore.collection("user").where("sudahaktif","==",true).get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          firestore.collection("user").doc(doc.id).collection("wbp").where("statuspenambahan","==","proccess").orderBy("namawbp","asc").get()
            .then(snapshot => {
              snapshot.forEach(() => {
                setTotalPHWbp(snapshot.size.toString());
              })
            }).catch(err => {
              console.log("Error getting sub-collection documents", err);
            })
        });
      }).catch(err => {
      console.log("Error getting documents", err);
    });
  };

  const getCountPetugas = () => {
    firestore.collection("petugas").get()
      .then(snapshot => {
        setTotalPetugas(snapshot.size.toString());
      }).catch(err => {
      console.log("Error getting documents", err);
    });
  };

  const getLessDate = (setgdate) => {
    var lessDate = new Date(`${setgdate.getMonth()+1}/${setgdate.getDate()}/${setgdate.getFullYear()} 23:59:01`);
    return lessDate;
  }
  const getGreaterDate = (setldate) => {
    var greaterDate = new Date(`${setldate.getMonth()+1}/${setldate.getDate()}/${setldate.getFullYear()} 00:00:01`);
    return greaterDate;
  }
  const [gdateValue, setGdateValue] = useState(getGreaterDate(new Date()));
  const [ldateValue, setLdateValue] = useState(getLessDate(new Date()));
  const getTodayPelaporan = () => {
    firestore.collection("pelaporan").orderBy("tanggal").where("tanggal",">",gdateValue).where("tanggal","<",ldateValue).get()
      .then(snapshot => {
        setTotalPelaporan(snapshot.size.toString());
      }).catch(err => {
      console.log("Error getting documents", err);
    });
  };
  
  const getTodayControls = () => {
    firestore.collection("kontrolpengamanan").orderBy("jamkontrol").where("jamkontrol",">",gdateValue).where("jamkontrol","<",ldateValue).get()
      .then(snapshot => {
        setTotalKontrol(snapshot.size.toString());
      }).catch(err => {
      console.log("Error getting documents", err);
    });
  };

  const getTodayWasrikControls = () => {
    firestore.collection("kontrolwasrik").orderBy("jamkontrol").where("jamkontrol",">",gdateValue).where("jamkontrol","<",ldateValue).get()
      .then(snapshot => {
        setTotalKontrolWasrik(snapshot.size.toString());
      }).catch(err => {
      console.log("Error getting documents", err);
    });
  };

  const getTodayTraffics = () => {
    firestore.collection("parkir").orderBy("masukjam").where("masukjam",">",gdateValue).where("masukjam","<",ldateValue).get()
      .then(snapshot => {
        setTotaltraffic(snapshot.size.toString());
      }).catch(err => {
      console.log("Error getting documents", err);
    });
  };

  useEffect(() => {
    if(totalPAkun||totalPHWbp||totalKontrol||totalKontrolWasrik||totalPelaporan||totalTraffic===0){getCountRequestingAccount();getCountRequestingWbp();getCountPetugas();getTodayControls();getTodayWasrikControls();getTodayTraffics();getTodayPelaporan();}else{return false;}
  });

  return (
    <div>
      <Navigation/>
      <Container className='mt-20' align='center'>
        <h1 style={{fontSize: 30}} className='mb-4' align='left'>Selamat Datang, {thisauthcontext.currentUser.displayName}</h1>
        <Row>
          <Col className='mb-10'>
              <Card bg='success' text='white' border='dark' align='left' >
                <Row className='no-gutters'>
                  <Col>
                    <Card.Header>Permohonan Akun Pengguna</Card.Header>
                    <Card.Body>
                      <Card.Title style={{fontSize: '300%'}}>{totalPAkun}</Card.Title>
                      <Card.Text>
                        Total permohonan akun pengguna.
                      </Card.Text>
                      <a href='/paps'><Button>Lihat</Button></a>
                    </Card.Body>
                  </Col>
                </Row>
            </Card>
          </Col>

          <Col className='mb-10'>
            <Card bg='warning' text='white' border='dark' align='left' >
                <Row className='no-gutters'>
                  <Col>
                    <Card.Header>Permohonan Hubung ke WBP</Card.Header>
                    <Card.Body>
                      <Card.Title style={{fontSize: '300%'}}>{totalPHWbp}</Card.Title>
                      <Card.Text>
                        Total permohonan terhubung ke WBP.
                      </Card.Text>
                      <a href='/phws'><Button>Lihat</Button></a>
                    </Card.Body>
                  </Col>
                </Row>
            </Card>
          </Col>

          <Col className='mb-10'>
            <Card bg='danger' text='white' border='dark' align='left' >
                <Row className='no-gutters'>
                  <Col>
                  <Card.Header>Pelaporan</Card.Header>
                    <Card.Body>
                      <Card.Title style={{fontSize: '300%'}}>{totalPelaporan}</Card.Title>
                      <Card.Text>
                        Pelaporan oleh keluarga WBP hari ini.
                      </Card.Text>
                      <a href='/pelaporan'><Button>Lihat</Button></a>
                    </Card.Body>
                  </Col>
                </Row>
            </Card>
          </Col>
        </Row>

        <Row>
        <Col className='mb-10'>
            <Card bg='info' text='white' border='dark' align='left' >
                <Row className='no-gutters'>
                  <Col>
                  <Card.Header>Petugas</Card.Header>
                    <Card.Body>
                      <Card.Title style={{fontSize: '300%'}}>{totalPetugas}</Card.Title>
                      <Card.Text>
                        Total data petugas.<br/><br/>
                      </Card.Text>
                      <a href='/petugass'><Button>Lihat</Button></a>
                    </Card.Body>
                  </Col>
                </Row>
            </Card>
          </Col>

          <Col className='mb-10'>
              <Card bg='dark' text='white' border='success' align='left' >
                <Row className='no-gutters'>
                  <Col>
                    <Card.Header>E - Traffic</Card.Header>
                    <Card.Body>
                      <Card.Title style={{fontSize: '300%'}}>{totalTraffic}</Card.Title>
                      <Card.Text>
                        Total data lalu lintas kendaraan di lapas hari ini.
                      </Card.Text>
                      <a href='/traffic'><Button>Lihat</Button></a>
                    </Card.Body>
                  </Col>
                </Row>
            </Card>
          </Col>

          <Col className='mb-10'>
            <Card bg='dark' text='white' border='success' align='left' >
                <Row className='no-gutters'>
                  <Col>
                    <Card.Header>E - Control Pengamanan</Card.Header>
                    <Card.Body>
                      <Card.Title style={{fontSize: '300%'}}>{totalKontrol}</Card.Title>
                      <Card.Text>
                        Total laporan kontrol petugas pengamanan hari ini.
                      </Card.Text>
                      <a href='/control'><Button>Lihat</Button></a>
                    </Card.Body>
                  </Col>
                </Row>
            </Card>
          </Col>
          
          <Col className='mb-10'>
            <Card bg='dark' text='white' border='success' align='left' >
                <Row className='no-gutters'>
                  <Col>
                    <Card.Header>E - Control Wasrik</Card.Header>
                    <Card.Body>
                      <Card.Title style={{fontSize: '300%'}}>{totalKontrolWasrik}</Card.Title>
                      <Card.Text>
                        Total laporan kontrol petugas wasrik hari ini.
                      </Card.Text>
                      <a href='/control'><Button>Lihat</Button></a>
                    </Card.Body>
                  </Col>
                </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Dashboard;