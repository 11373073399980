import React, { useState, useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import PenggunaDataService from "../../Services/PenggunaService";
import PermohonanPenggunaDetail from "./PermohonanPenggunaDetail"
import Navigation from "../Navigation"

function PermohonanPenggunaList() {
  const queryRef = PenggunaDataService.getRequestingAccount();
  const [searchvalue, setSearchValue] = useState("");
  const [limit, setLimit] = useState(25);
  const [doclength, setDoclength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [currentPengguna, setCurrentPengguna] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [penggunas, loading, error] = useCollection(queryRef.orderBy("tgldaftar","desc").startAt(searchvalue).endAt(searchvalue + "\uf8ff").limit(limit));

  const getCountDoc = () => {
    queryRef.get().then(snapshot => {
      setDoclength(snapshot.size);
      }).catch(err => {
      console.log("Error getting documents", err);
    });
  };
  getCountDoc();
  
  const refreshList = () => {
    setCurrentPengguna(null);
    setCurrentIndex(-1);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const setActivePengguna = (pengguna, index) => {
    const { alamat, fotodiri, fotoktp, jeniskelamin, nama, statusakun, telp,
    tgldaftar, ticket, uid } = pengguna.data();

    setCurrentPengguna({
      id: pengguna.id,
      alamat,
      fotodiri,
      fotoktp,
      jeniskelamin,
      nama,
      statusakun,
      telp,
      tgldaftar,
      ticket,
      uid,
    });

    setCurrentIndex(index);
  };

  useEffect(() => {
    if(doclength>0){
      if(doclength<=limit){
        setHasMore(false);
      }else{
        setHasMore(true);
      }
    }else{return false;}
  });

  return(
      <div>
        <Navigation />  
        <div className="list row mt-20 mx-10">
        <div className="col-md-6">
        <h1 className="text-3xl mb-2 text-center font-bold">Daftar Akun Pemohon</h1>
        <div className="form-group">
          <label htmlFor="cari">Cari Berdasarkan Nama</label>
          <input
            type="text"
            placeholder="masukkan nama"
            className="form-control"
            id="cari"
            name="cari"
            onChange={handleSearchChange}
          />
        </div><hr/><br/>
          {error && <strong>Error: {error}</strong>}
          {loading && <span>Memuat data...</span>}
          <ul className="list-group">
            { !loading && penggunas &&
              penggunas.docs.map((pengguna, index) => (
                <li
                  className={"list-group-item " + (index === currentIndex ? "active" : "")}
                  onClick={() => setActivePengguna(pengguna, index)}
                  key={pengguna.id}
                >
                  {index+1}.{' '}{ pengguna.data().nama }
                </li>
              ))}
              {hasMore && <button className="btn btn-secondary" onClick={() => setLimit(limit+25)}>Lihat lainnya</button>}
          </ul>
        </div>
        <div className="col-md-6">
          {currentPengguna ? (
            <PermohonanPenggunaDetail pengguna={currentPengguna} refreshList={refreshList} />
          ) : (
            <div>
              <br/><br/><br/>
              <p><strong align='center'>Pilih salah satu data untuk melihat detailnya...</strong></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PermohonanPenggunaList;