import {firestore} from "../firebase";

const db = firestore.collection("/user");

const getAllMessages = (iduser) => {
  return db.doc(iduser).collection("chat").orderBy("waktu","asc");
};

const getIncomingChat = () => {
  const datanya = [];
  db.where("sudahaktif","==",true).get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        db.doc(doc.id).collection("chat").where("terbalas","==",false).orderBy("waktu","asc").get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              datanya.push({...doc.data(), id: doc.id})
            })
          }).catch(err => {
            console.log("Error getting sub-collection documents", err);
          })
      });
    }).catch(err => {
    console.log("Error getting documents", err);
  });
  return datanya;
};

const sendMessage = (iduser, data) => {
  db.doc(iduser).collection("chat").where("terbalas","==",false).orderBy("waktu","asc").get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        db.doc(iduser).collection("chat").doc(doc.id).update({terbalas:true});
      })
    }).catch(err => {
      console.log("Error getting collection documents", err);
  })    
  return db.doc(iduser).collection("chat").add(data);
};

const remove = (iduser, id) => {
  return db.doc(iduser).collection("chat").doc(id).delete();
};

const ChatService = {
  getAllMessages,
  getIncomingChat,
  sendMessage,
  remove
};

export default ChatService;