import React, { useState } from "react";
import KeluargaDataService from "../../Services/KeluargaService";
import Navigation from "../Navigation";
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

const AddPetugas = () => {
  var { iduser, username } = useParams()
  const [nama, setNama] = useState("");
  const [nik, setNik] = useState("");
  const [hubungan, setHubungan] = useState("");
  const [error, setError] = useState(null);

  function showMessage (m) {
    Swal.fire({
      text: m,
      icon: 'info',
    }).then(()=>{
      window.location.replace("/keluarganya/"+iduser);
    })
  };

  const createKeluargaHandler = async (event, nama, nik, hubungan) => {
    event.preventDefault();
    setError(null)
    if (nama === "" || nik === ""|| hubungan === "") {
      setError("Masih ada kolom yang kosong!");
    }else{
      Swal.fire({
        title: 'Simpan Data?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Batal',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya'
      }).then((result) => {
        if (result.isConfirmed) {
            const data = {
              nama: nama,
              nik: nik,
              hubungan: hubungan,
            };
            KeluargaDataService.create(iduser, data)
            .then(() => {
              showMessage("Data keluarga ditambahkan");
            })
            .catch((e) => {
              console.log(e);
            });
          }
      });
    }
    setNama("");setNik("");setHubungan("");
  };

  const onChangeHandler = event => {
    const { name, value } = event.currentTarget;
    if (name === "keluargaNik") {
      setNik(value);
    } else if (name === "keluargaHubungan") {
      setHubungan(value);
    } else if (name === "keluargaNama") {
      setNama(value);
    }
  };

  return (
    <div>
      <Navigation/>
      <div className="mt-20">
        <h1 className="text-3xl mb-2 text-center font-bold">Tambahkan Data Keluarga Untuk {username}</h1>
        <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
          {error !== null && (
            <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
              {error}
            </div>
          )}
          <form className="">
            <label htmlFor="keluargaNik" className="block">
              NIK :
            </label>
            <input
              type="text"
              className="my-1 p-1 w-full "
              name="keluargaNik"
              value={nik}
              placeholder="Masukkan NIK"
              id="keluargaNik"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={event => onChangeHandler(event)}
              required/>
            <label htmlFor="keluargaNama" className="block">
              Nama :
            </label>
            <input
              type="text"
              className="my-1 p-1 w-full"
              name="keluargaNama"
              value={nama}
              placeholder="Masukkan nama"
              id="keluargaNama"
              onChange={event => onChangeHandler(event)}
              required/>
              <label htmlFor="keluargaHubungan" className="block">
              Hubungannya dengan {username} :
            </label>
            <input
              type="text"
              className="my-1 p-1 w-full"
              name="keluargaHubungan"
              value={hubungan}
              placeholder="Masukkan hubungan"
              id="keluargaHubungan"
              onChange={event => onChangeHandler(event)}
              required/>
            <br/>
            <button
              className="bg-green-400 hover:bg-green-500 w-full py-2 text-white"
              onClick={event => {
                createKeluargaHandler(event, nama, nik, hubungan);
              }}
            >
              Tambahkan
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPetugas;
