import React, { useState } from "react";
import { Link } from "react-router-dom";
import ChatDataService from "../../Services/ChatService";
import Navigation from "../Navigation"

function ChatList() {
  const [chats] = useState(ChatDataService.getIncomingChat());
  const [currentChat, setCurrentChat] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);


  const refreshList = () => {
    setCurrentChat(null);
    setCurrentIndex(-1);
  };

  const handleHideBT = (event) => {
    event.target.style.display = 'none';
 }

  return(
      <div>
        <Navigation />
        <div className="list row mt-20 mx-10">
        <div className="col-md-10">
        <h1 className="text-3xl mb-2 text-center font-bold">Kotak Masuk Layanan Informasi</h1>
          <ul className="list-group">
            { chats.map(function(chat, index){
                return <li
                className={"list-group-item " + (index === currentIndex ? "active" : "")}
                onClick={() =>window.open(`/chatnya/${chat.userid}/${chat.username}`, "_blank")}
                key={chat.id}
              >
                {index+1}.{' '}{ chat.username }<br/><br/>{chat.pesan}<br/><br/><hr/><bold>Klik untuk menjawab.</bold><hr/>
              </li>
          })}
          </ul>
          <Link to="/chat#" onClick={handleHideBT} className="btn btn-primary">Tampilkan Daftar</Link>
        </div>
      </div>
    </div>
  );
}

export default ChatList;