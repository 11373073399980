import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { functions } from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAge58Hf7_b0l4ZO_-Gzw1_OVPpf-qtxpY",
  authDomain: "kedungpane-public-services.firebaseapp.com",
  projectId: "kedungpane-public-services",
  storageBucket: "kedungpane-public-services.appspot.com",
  messagingSenderId: "1059329338160",
  appId: "1:1059329338160:web:896bd35a14b0363c6e76f5",
  measurementId: "G-XL8YTT84NN"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage()

export const generateAdminDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const adminRef = firestore.doc(`admin/${userAuth.uid}`);
  const snapshot = await adminRef.get();

  if (!snapshot.exists) {
    const { email, displayName, photoURL } = userAuth;
    const createdAt = new Date();
    try {
      await adminRef.set({
        createdAt,
        displayName,
        email,
        photoURL,
        ...additionalData
      });
    } catch (error) {
      console.error("Error creating admin document", error);
    }
  }
  return getAdminDocument(userAuth.uid);
};

const getAdminDocument = async uid => {
  if (!uid) return null;
  try {
    const adminDocument = await firestore.doc(`admin/${uid}`).get();

    return {
      uid,
      ...adminDocument.data()
    };
  } catch (error) {
    console.error("Error fetching admin", error);
  }
};
