import React, { useState, useContext } from "react";
import { AuthContext } from "../../Services/Authentication";
import AdminDataService from "../../Services/AdminService";
import { Image } from 'react-bootstrap';
import Swal from 'sweetalert2'

const AdminDetail = (props) => {
  const initialAdminState = {
    key: null,
    createdAt: {
      nanoseconds: "",
      seconds: "",
    },
    displayName: "",
    email: "",
    photoURL: "",
  };
  const thisauthcontext = useContext(AuthContext);
  const [currentAdmin, setCurrentAdmin] = useState(initialAdminState);

  const { admin } = props;
  if (currentAdmin.id !== admin.id) {
    setCurrentAdmin(admin);
  }

  function showMessage (m) {
    Swal.fire({
      text: m,
      icon: 'info',
    })
  };

  const deleteAdmin = () => {
    Swal.fire({
      title: 'Yakin ingin menghapus?',
      text: "data yang terhapus tidak dapat dikembalikan!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus'
    }).then((result) => {
      if (result.isConfirmed) {
        if(currentAdmin.id===thisauthcontext.currentUser.uid){
          showMessage('Tidak dapat menghapus akun Anda sendiri.')
        }else{
          AdminDataService.remove(currentAdmin.id)
          .then(() => {
            props.refreshList()
          })
          .catch((e) => {
            console.log(e);
          });
        }
      }
    })
  };

  return (
    <div className='mx-10'>
        {currentAdmin ? (
        <div className="edit-form">
          <h1 className="text-3xl mb-2 text-center font-bold">Detail Data Admin (fix)</h1>
          <form>
            <div className="form-group">
              <label htmlFor="diplayName">Username</label>
              <input
                type="text"
                className="form-control"
                id="diplayName"
                name="diplayName"
                value={currentAdmin.displayName}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={currentAdmin.email}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="photoURL">Foto Profil</label>
              <Image id="photoURL" src={currentAdmin.photoURL} alt='foto profil tidak diganti' rounded />
            </div>

        </form>

          <button className="btn btn-danger mr-2" onClick={deleteAdmin}>
            Hapus
          </button>
        </div>
      ) : (
        <div>
          <br/>
          <p><strong>Pilih salah satu data untuk melihat detailnya...</strong></p>
        </div>
      )}
    </div>
  );
};

export default AdminDetail;