import React, { useState, useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import KeluargaDataService from "../../Services/KeluargaService";
import PermohonanKeluargaDetail from "./PermohonanKeluargaDetail"
import Navigation from "../Navigation"

function PermohonanKeluargaList() {
  const queryRef = KeluargaDataService.getPengajuanKeluarga();
  const [limit, setLimit] = useState(25);
  const [doclength, setDoclength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [currentPermohonan, setCurrentPermohonan] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [permohonans, loading, error] = useCollection(queryRef.limit(limit));

  const getCountDoc = () => {
    queryRef.get().then(snapshot => {
      setDoclength(snapshot.size);
      }).catch(err => {
      console.log("Error getting documents", err);
    });
  };
  getCountDoc();
  
  const refreshList = () => {
    setCurrentPermohonan(null);
    setCurrentIndex(-1);
  };

  const setActivePermohonan = (permohonan, index) => {
    const { nama, fotokk } = permohonan.data();

    setCurrentPermohonan({
      id: permohonan.id,
      nama,
      fotokk,
    });

    setCurrentIndex(index);
  };

  useEffect(() => {
    if(doclength>0){
      if(doclength<=limit){
        setHasMore(false);
      }else{
        setHasMore(true);
      }
    }else{return false;}
  });

  return(
      <div>
        <Navigation />  
        <div className="list row mt-20 mx-10">
        <div className="col-md-6">
        <h1 className="text-3xl mb-2 text-center font-bold">Daftar Permohonan Data Keluarga Sebagai Pengikut</h1>
        <hr/><br/>
          {error && <strong>Error: {error}</strong>}
          {loading && <span>Memuat data...</span>}
          <ul className="list-group">
            { !loading && permohonans &&
              permohonans.docs.map((permohonan, index) => (
                <li
                  className={"list-group-item " + (index === currentIndex ? "active" : "")}
                  onClick={() => setActivePermohonan(permohonan, index)}
                  key={permohonan.id}
                >
                  {index+1}.{' '}{ permohonan.data().nama }
                </li>
              ))}
              {hasMore && <button className="btn btn-secondary" onClick={() => setLimit(limit+25)}>Lihat lainnya</button>}
          </ul>
        </div>
        <div className="col-md-6">
          {currentPermohonan ? (
            <PermohonanKeluargaDetail permohonan={currentPermohonan} refreshList={refreshList} />
          ) : (
            <div>
              <br/><br/><br/>
              <p><strong align='center'>Pilih salah satu data untuk melihat detailnya...</strong></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PermohonanKeluargaList;