import React, { useContext, useState } from "react";
import { AuthContext } from "../Services/Authentication";
import { auth, storage, firestore } from "../firebase";
import Navigation from './Navigation'
import Swal from 'sweetalert2'
import { Image } from 'react-bootstrap';
const ProfilePage = () => {
  const thisauthcontext = useContext(AuthContext);

  const [file, setFile] = useState(null);
  const [url, setURL] = useState('');
  const [progress, setProgress] = useState(0);

  async function gantiFotoProfil () {
    const { value: file } = await Swal.fire({
      title: 'Pilih Foto Profil',
      text: 'Foto akan secara otomatis tersimpan setelah diupload',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Upload',
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Upload foto profil'
      }
    })
    
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        e.preventDefault();
        const uploadTask = storage.ref(`/fotoadmin/${thisauthcontext.currentUser.displayName}_${file.name}`).put(file);
        uploadTask.on(
          "state_changed",
          snapshot => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progress);
          },
          error => {
            console.log(error);
          },
          () => {
            Swal.fire({
              icon: 'success',
              title: 'Foto profil berhasil diganti',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              storage
              .ref("fotoadmin")
              .child(thisauthcontext.currentUser.displayName+"_"+file.name)
              .getDownloadURL()
              .then((url) => {
                setURL(url)
                try{
                  firestore.doc(`admin/${thisauthcontext.currentUser.uid}`).update({
                    photoURL: url
                  }).then(()=>{
                    window.location.reload()
                  })
                } catch (error) {
                  console.error("Error creating admin document", error);
                }
              })
            });
          }
        );
      }
      reader.readAsDataURL(file)
    }
  };

  const logoutConfirm = () => {
    Swal.fire({
      title: 'Yakin ingin keluar?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya'
    }).then((result) => {
      if (result.isConfirmed) {
        auth.signOut()
      }
    })
  };
  
  return (
    <div>
      <Navigation/>
      <div className = "mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8 mt-20">
        <div className="flex border flex-col items-center md:flex-row md:items-start border-blue-400 px-3 py-4">
          <div className="border border-blue-300" >
            <Image style={{height: "200px", width: "200px"}} src={thisauthcontext.currentUser.photoURL} onError={(e) => {
            e.target.src = process.env.PUBLIC_URL+'/avatar-adm.png'}} rounded />
          </div>
          <div className = "md:pl-4">
          <h2 className = "text-2xl font-semibold">{thisauthcontext.currentUser.displayName}</h2>
          <h3 className = "italic">{thisauthcontext.currentUser.email}</h3>
          </div>
        </div>
        <div className = "mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8">
          <button onClick={gantiFotoProfil} className = "w-full py-3 bg-blue-600 mt-2 text-white">Ganti Foto Profil</button>
        </div>
        <button className = "w-full py-3 bg-red-600 mt-2 text-white" onClick = {logoutConfirm}>Keluar</button>
      </div>
    </div>
  ) 
};

export default ProfilePage;

