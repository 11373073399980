import React, { useState, useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import ServeiDataService from "../../Services/SurveiService";
import Navigation from "../Navigation";
import DatePicker from 'react-date-picker';
import { Table, Row, Col } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';

function SurveiList() {
  const [dateValue, setDateValue] = useState(new Date());

  const getLessDate = (setgdate) => {
    var lessDate = new Date(`${setgdate.getMonth()+1}/${setgdate.getDate()}/${setgdate.getFullYear()} 23:59:01`);
    return lessDate;
  }

  const getGreaterDate = (setldate) => {
    var greaterDate = new Date(`${setldate.getMonth()+1}/${setldate.getDate()}/${setldate.getFullYear()} 00:00:01`);
    return greaterDate;
  }

  const [gdateValue, setGdateValue] = useState(getGreaterDate(dateValue));
  const [ldateValue, setLdateValue] = useState(getLessDate(dateValue));
  const [surveis, loading, error] = useCollection(ServeiDataService.getSurvei(gdateValue,ldateValue));
  const [totalSurvei, setTotalSurvei] = useState(0);
  const [totalSurveiTp, setTotalSurveiTp] = useState(0);
  const [totalSurveiP, setTotalSurveiP] = useState(0);
  const [totalSurveiSp, setTotalSurveiSp] = useState(0);
  const [chartData, setChartData] = useState(null);

  const onDateChange = (date) => {
    setChartData(null);
    setDateValue(date);
    setGdateValue(getGreaterDate(date));
    setLdateValue(getLessDate(date));
  }

  const getCountSurvei = () => {
    ServeiDataService.getSurvei(gdateValue,ldateValue).get()
        .then(snapshot => {
          setTotalSurvei(snapshot.size);
        }).catch(err => {
        console.log("Error getting documents", err);
      });
  };

  const getCountSurveiTp = () => {
    ServeiDataService.getSurveiTp(gdateValue,ldateValue).get()
        .then(snapshot => {
          setTotalSurveiTp(snapshot.size);
        }).catch(err => {
        console.log("Error getting documents", err);
      });
  };

  const getCountSurveiP = () => {
    ServeiDataService.getSurveiP(gdateValue,ldateValue).get()
        .then(snapshot => {
          setTotalSurveiP(snapshot.size);
        }).catch(err => {
        console.log("Error getting documents", err);
      });
  };

  const getCountSurveiSp = () => {
    ServeiDataService.getSurveiSp(gdateValue,ldateValue).get()
        .then(snapshot => {
          setTotalSurveiSp(snapshot.size);
        }).catch(err => {
        console.log("Error getting documents", err);
      });
  };

  useEffect(() => {
    if(chartData==null){
      getCountSurvei();getCountSurveiTp();getCountSurveiP();getCountSurveiSp();
      setChartData({
        labels: ['Tidak Puas', 'Puas', 'Sangat Puas'],
        datasets: [
            {
                label: 'Survei Kepuasan Masyarakat',
                data: [
                    totalSurveiTp/totalSurvei*100,
                    totalSurveiP/totalSurvei*100,
                    totalSurveiSp/totalSurvei*100
                ],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(54, 162, 235, 0.6)'
                ],
                borderWidth: 3
            }
        ]
      });
    }else{return false;}
  });

  return(
      <div>
        <Navigation />  
        <div className="list row mt-20 mx-10">
        <div className="col-md-10">
        <h1 className="text-3xl mb-2 text-left font-bold">Survei Kepuasan Masyarakat</h1>
        <div className="form-group">
          <Row>
            <Col>
              <label htmlFor="tanggal">Pilih Tanggal</label><br/>
              <DatePicker
                id="tanggal"
                clearIcon={null}
                onChange={onDateChange}
                value={dateValue}
              />
            </Col>
            <Col>
              <label htmlFor="chart">Grafik ( % )</label><br/>
              <Pie id="chart" data={chartData} />
            </Col>
          </Row>
        </div><hr/><br/>
          {error && <strong>Error: {error}</strong>}
          {loading && <span>Memuat data...</span>}
          <ul className="list-group">
                <Table striped bordered hover>
                { !loading &&
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Pengirim</th>
                    <th>Rating</th>
                    <th>Kritik dan Saran</th>
                  </tr>
                </thead>}
                { !loading && surveis && surveis.docs.map((survei, index) => (
                <tbody>
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{survei.data().pengirim}</td>
                    <td>{(() => {
                        const ratingnya = survei.data().rating===1;
                        if (ratingnya===1) {
                          return (
                            <div>⭐ (Tidak Puas)</div>
                          )
                        } else if (ratingnya===2) {
                          return (
                            <div>⭐⭐ (Puas)</div>
                          )
                        } else {
                          return (
                            <div>⭐⭐⭐ (Sangat Puas)</div>
                          )
                        }
                      })()}
                    </td>
                    <td>{survei.data().pesan}</td>
                  </tr>
                </tbody>))}
              </Table>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SurveiList;