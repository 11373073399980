import {firestore} from "../firebase";

const db = firestore.collection("/kirimbarang");

const getSentKirang = (gdate,ldate) => {
  return db.orderBy("tglterkirim").where("tglterkirim",">",gdate).where("tglterkirim","<",ldate).where("status","==","terkirim");
};

const create = (data) => {
  return db.add(data);
};

const update = (id, value) => {
  return db.doc(id).update(value);
};

const remove = (id) => {
  return db.doc(id).delete();
};

const KirangService = {
  getSentKirang,
  create,
  update,
  remove
};

export default KirangService;