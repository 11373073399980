import {firestore, storage} from "../firebase";

const dbp = firestore.collection("/admin");
const db = firestore.collection("/user");

const getPengajuanKeluarga = () => {
  return dbp.doc("UserAppSettings").collection("PengajuanPengikut");
};

const getVerifiedKeluarga = (iduser, searchvalue) => {
  return db.doc(iduser).collection("keluarga").orderBy("nama","asc").startAt(searchvalue).endAt(searchvalue + "\uf8ff");
};

const removeP = (iduser) => {
  return dbp.doc("UserAppSettings").collection("PengajuanPengikut").doc(iduser).delete();
};

const create = (iduser, data) => {
  return db.doc(iduser).collection("keluarga").add(data);
};

const update = (iduser, id, value) => {
  return db.doc(iduser).collection("keluarga").doc(id).update(value);
};

const remove = (iduser, id) => {
  return db.doc(iduser).collection("keluarga").doc(id).delete();
};

const delfoto = (url) => {
  var fileRef = storage.refFromURL(url);
  return fileRef.delete();
};

const KeluargaService = {
  getPengajuanKeluarga,
  getVerifiedKeluarga,
  create,
  update,
  remove,
  removeP,
  delfoto
};

export default KeluargaService;